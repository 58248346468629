import { routes, statusRoutes } from '@/router'
import store from '@/store'
import { UNITY_QUERY_KEY_ORGAN, KEY_isMenuOnly } from '@/CONST'

/**
 * 设置中只展示子集菜单
 * @param {*} menus
 * @param {*} to
 * @returns
 */
function setIsOnlyMenu(menus, to) {
  const node = getCurrentParentNode(menus, to.path)
  let menusPre = []
  if (node?.[KEY_isMenuOnly]) {
    menusPre = node.children || []
  } else {
    menusPre = menus?.filter(m => !m[KEY_isMenuOnly])
  }

  store.commit('user/SET_PRE_MENUS', menusPre)
  return menusPre
}

/**
 * 获取第一个菜单的url，默认跳转地址
 * @param {*} menus
 * @returns
 */
function getFirstMenuUrl(menus) {
  const firstMenu = getFirstDeepChild(menus)
  let firstUrl = firstMenu?.path || firstMenu?.extra?.path || '/'

  const { agentId } = store.state.app.appInfo
  const preUrl = '/' + agentId

  firstUrl = firstUrl.startsWith(preUrl) ? firstUrl : preUrl + firstUrl

  return firstUrl
}

/**
 * 路由跳转处理
 * @param {*} param0
 * @param {*} menus
 * @param {*} replace
 */
export function redirectRouter({ to, next } = {}, menus, replace = false) {
  const isWhitePath = routes.find(route => route.path === to.path)
  const isStatusPath = statusRoutes.find(route => route.path === to.path)

  // 跳转拦截：是否携带机构id，UNITY_QUERY_KEY_ORGAN
  const routerNext = (params = {}) => {
    if (typeof params === 'string') {
      params = { path: params }
    }
    const query = { ...to.query }
    // app框架：移除统一门户携带的应用机构id
    if (query[UNITY_QUERY_KEY_ORGAN] !== undefined) {
      delete query[UNITY_QUERY_KEY_ORGAN]
    }

    next({ ...params, query })
  }

  const menusPre = setIsOnlyMenu(menus, to)

  if (menusPre?.length) {
    const firstUrl = getFirstMenuUrl(menusPre)

    // 当前地址为微应用
    const isMicroRoot = firstUrl.startsWith(location.pathname) && firstUrl !== location.pathname && !!replace

    if (isStatusPath || to.path === '/' || isMicroRoot) {
      routerNext({ path: firstUrl })
    } else {
      if (replace) {
        routerNext({ ...to, replace: true })
      } else {
        routerNext()
      }
    }
  } else {
    // 无权限
    if (isWhitePath || isStatusPath) {
      routerNext()
    } else {
      routerNext('/no-permission')
    }
    if (to?.meta?.title) document.title = to.meta.title
  }
}

/**
 *获取第一个节点的叶子节点
 * @param {*} menus
 * @returns
 */
export function getFirstDeepChild(menus) {
  const { length } = menus
  if (!length) return null

  const firstEl = menus[0]
  if (!firstEl?.children?.length) return firstEl
  return getFirstDeepChild(firstEl.children)
}

/**
 * 获取当前路由的菜单父级节点
 * @param {*} arr
 * @param {*} key
 * @returns
 */
function getCurrentParentNode(arr, key) {
  for (let index = 0; index < arr.length; index++) {
    const a = arr[index]
    if (a?.children?.some(c => c.path === key)) {
      return a
    } else if (a.children) {
      const obj = getCurrentParentNode(a.children, key)
      if (obj) return obj
    }
  }
}

