class MicroApp {
  constructor(initGlobalState) {
    const self = this
    this.apps = []
    this.action = {
      commitGlobalState: this.commitGlobalState.bind(self)
    }

    this.globalState = {
      state: {}
    }

    this.microState = initGlobalState(this.globalState)
  }

  commitGlobalAction(key, fn) {
    if (!fn || (typeof fn !== 'function')) {
      console.error(`[commitGlobalAction] fn is not a function`)
      return
    }

    if (this.globalState.action[key]) {
      console.warn(`[commitGlobalAction] ${key} 已经存在`)
      return
    }

    this.globalState.action[key] = fn
  }

  commitGlobalState(key, val) {
    if (key instanceof Object) {
      val = key
      key = ''
    }

    if (key) {
      this.globalState.state[key] = { ...this.globalState.state[key], ...val }
    } else {
      this.globalState.state = { ...this.globalState.state, ...val }
    }
    this.setGlobalState()
  }

  setGlobalState() {
    this.microState.setGlobalState(this.globalState)
  }
}

let microApp
export function getMicroApp(initGlobalState) {
  if (microApp) return microApp
  microApp = new MicroApp(initGlobalState)

  return microApp
}

export default MicroApp

