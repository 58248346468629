<!--  -->
<template>
  <el-popover
    placement="bottom"
    width="300"
    class="bell"
    popper-class="saas-popper-wrap"
    v-bind="popoverConfig"
    @show="getData"
  >
    <el-badge slot="reference" is-dot :hidden="!msgReadStatus" class="icon-wrap">
      <i class="el-icon-bell" />
    </el-badge>
    <div class="msg-panel">
      <div class="msg-title">站内消息通知</div>
      <div class="msg-content">
        <div v-if="!listData.length" class="msg-content-noContent">
          <img src="~shared/assets/no_msg.png" alt="">
          <p class="text">还没有消息哦~</p>
        </div>
        <template v-else>
          <div v-for="item in listData" :key="item.id" class="msg-content-item">
            <div class="msg-content-item--title">
              <div class="title saas-ellipsis">
                <i v-show="!item.state" class="title-dot" />
                {{ item.title }}
              </div>
              <div class="createTime">{{ parseTime(item.createTime) }}</div>
            </div>
            <div class="msg-content-item--main saas-ellipsis">{{ item.content }}...</div>
          </div>
        </template>
      </div>
      <div class="msg-button" @click="messageManage">消息管理</div>
    </div>
  </el-popover>
</template>

<script>
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapState } from 'vuex'
import { popoverConfig, searchForm } from './config'
import { sleep } from 'shared'

export default {
  name: 'BellPopper',
  data() {
    return {
      listData: [],
      popoverConfig
    }
  },
  computed: {
    ...mapState('app', ['msgReadStatus'])
  },
  created() {
    this.getMsgStatus()
  },

  methods: {
    ...mapActions('app', ['getAllMessageState']),
    async getMsgStatus() {
      const next = async() => {
        try {
          await this.getAllMessageState()
        } catch (error) {
          console.log('error>>>', error)
        } finally {
          await sleep(1000 * 10)
          next()
        }
      }
      next()
    },
    getData() {
      this.$api.getNotifyList(this.$formatParams(searchForm)).then(res => {
        const { data } = cloneDeep(res)
        this.listData = data?.records || []
      })
    },
    messageManage() {
      const target = 'Message'
      if (this.$route.name === target) {
        this.$router.replace({ path: '/redirect/' + Date.now() })
      } else {
        this.$router.push({ name: target })
      }
      this.$emit('close')
    },
    parseTime(time) {
      const nowDate = moment(new Date())
      const mDate = moment(time)
      const diff = nowDate.diff(mDate, 'years')
      if (diff >= 1) return mDate.format('YYYY-MM-DD')

      return moment(time).calendar(null, {
        sameDay: 'HH:mm',
        lastDay: '[昨天] HH:mm',
        lastWeek: 'MM-DD',
        sameElse: 'MM-DD'
      })
    }
  }
}
</script>
<style lang='scss' scoped>
  .el-icon-bell {
    font-size: 20px;
  }
  .icon-wrap{
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    &:hover{
      background:$--color-primary-light-2;
    }
    ::v-deep .el-badge__content{
      border: none;
    }
  }
  .msg-panel{
    .msg-title{
      padding: 10px 15px;
      border-bottom: $--border-base;
      font-weight: bold;
    }
    .msg-content{
      .msg-content-item + .msg-content-item{
        border-top: $--border-base;
      }
      &-item{
        padding: 15px;
        cursor: pointer;
        &:hover{
          background: $--color-primary-light-9;
          .title{
            color: $--color-primary;
          }
        }
        &--title{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          .title{
            font-size: $--font-size-base;
            &-dot{
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #EE4B38;
            }
          }
          .createTime{
            font-size: $--font-size-extra-small;
            color: $--color-text-secondary;
            flex-shrink: 0;
          }
        }
        &--main{
          color: $--color-text-placeholder;
        }
      }
      &-noContent{
        padding: 50px 0;
        text-align: center;
        .text{
          margin-top: 20px;
        }
      }
    }
    .msg-button{
      padding: 15px;
      text-align: center;
      cursor: pointer;
      color: $--color-primary;
      border-top: $--border-base;
    }
  }
</style>
