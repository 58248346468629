<!--  -->
<template>
  <el-popover
    placement="bottom"
    width="300"
    class="bell"
    popper-class="saas-popper-wrap"
    v-bind="popoverConfig"
    @show="showData"
  >
    <el-badge slot="reference" is-dot :hidden="!msgReadStatus" class="icon-wrap">
      <i class="el-icon-bell" />
    </el-badge>
    <div v-loading="typeLoading" class="msg-panel">
      <div class="msg-title">
        <!-- 站内消息通知 -->
        <span
          v-for="item in messageType"
          :key="item.code"
          :class="['msg-title-item',item.code===tabActiveObj.code && 'isActive']"
          @click="tabActiveObj = item"
        >
          <el-badge :value="messageTypeCount[item.code]" class="msg-title-item-badge">
            {{ item.tabName }}
          </el-badge>

        </span>
      </div>
      <div v-loading="panelLoading" class="msg-content">
        <div v-if="!listData.length && !panelLoading" class="msg-content-noContent">
          <img src="~shared/assets/no_msg.png" alt="">
          <p class="text">还没有消息哦~</p>
        </div>
        <template v-else>
          <div v-for="item in listData" :key="item.id" class="msg-content-item">
            <div class="img">
              <el-badge is-dot :hidden="item.state">
                <img :src="MESSAGE_IMG_TYPE[tabActiveObj.tabName]" alt="">
              </el-badge>
            </div>
            <div class="main">
              <div class="saas-ellipsis main-title">
                <span v-if="tabActiveObj.tabName === '告警'" :class="['main-title-type',`type-${item.placeholderObject.alarmLevel}`]">{{ alarmType[item.placeholderObject.alarmLevel-1] }}</span>
                {{ item.title }}
              </div>
              <div class="saas-ellipsis main-content">{{ item.content }}</div>
            </div>
            <div class="time">
              <div class="createTime">{{ parseTime(item.createTime) }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="msg-button" @click="messageManage">查看所有</div>
    </div>
  </el-popover>
</template>

<script>
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapState } from 'vuex'
import { popoverConfig, searchForm } from '../config'
import { sleep } from 'shared'

const MESSAGE_TYPE_TITLE = ['告警', '事件', '公告', '审核']
const MESSAGE_IMG_TYPE = {}
MESSAGE_TYPE_TITLE.forEach(m => {
  MESSAGE_IMG_TYPE[m] = require(`shared/assets/message_images/${m}.png`)
})

export default {
  name: 'BellPopper',
  data() {
    return {
      MESSAGE_IMG_TYPE,
      typeLoading: false,
      panelLoading: false,
      listData: [],
      popoverConfig,
      messageType: [],
      messageTypeCount: {},
      tabActiveObj: {},
      alarmType: ['低', '中', '高']
    }
  },
  computed: {
    ...mapState('app', ['msgReadStatus'])
  },
  watch: {
    tabActiveObj(nv, ov) {
      if (nv.code) this.getData()
    }
  },
  created() {
    this.getMsgStatus()
  },

  methods: {
    ...mapActions('app', ['getAllMessageState']),
    async getMsgStatus() {
      const next = async() => {
        try {
          await this.getAllMessageState()
        } catch (error) {
          console.log('error>>>', error)
        } finally {
          await sleep(1000 * 10)
          next()
        }
      }
      next()
    },
    /** 获取类型、未读数量 */
    showData() {
      this.getMessageType()
      this.getMessageTypeCount()
    },
    getData() {
      this.listData = []
      this.panelLoading = true
      this.$api.getNotifyList(this.$formatParams({ ...searchForm, messageTypeCode: this.tabActiveObj.code })).then(res => {
        const { data } = cloneDeep(res)
        this.listData = data?.records || []
      }).finally(_ => {
        this.panelLoading = false
      })
    },
    /** 获取消息类型 */
    getMessageType() {
      if (this.messageType.length > 0) return // 类型减少请求
      this.typeLoading = true
      this.$api.getMessageType({ data: { base: { page: '0-0' }}}).then(res => {
        const { records } = res?.data || {}

        this.messageType = records.map(r => {
          r.tabName = MESSAGE_TYPE_TITLE.find(t => r.name.includes(t))
          return r
        })
        this.tabActiveObj = records[0]
      }).finally(_ => { this.typeLoading = false })
    },
    /** 获取消息类型未读数量 */
    getMessageTypeCount() {
      this.$api.getMessageTypeCount({ data: { state: false }}).then(res => {
        const records = res?.data || []
        const obj = {}
        records?.forEach(r => {
          obj[r.messageTypeCode] = r.count
        })
        this.messageTypeCount = obj
      })
    },
    messageManage() {
      const target = 'Message'
      if (this.$route.name === target) {
        this.$router.replace({ path: '/redirect/' + Date.now() })
      } else {
        this.$router.push({ name: target })
      }
      this.$emit('close')
    },
    parseTime(time) {
      const nowDate = moment(new Date())
      const mDate = moment(time)
      const diff = nowDate.diff(mDate, 'years')
      if (diff >= 1) return mDate.format('YYYY-MM-DD')

      return moment(time).calendar(null, {
        sameDay: 'HH:mm',
        lastDay: '[昨天] HH:mm',
        lastWeek: 'MM-DD',
        sameElse: 'MM-DD'
      })
    }
  }
}
</script>
<style lang='scss' scoped>
  .el-icon-bell {
    font-size: 20px;
  }
  .icon-wrap{
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    &:hover{
      background:$--color-primary-light-2;
    }
    ::v-deep .el-badge__content{
      border: none;
    }
  }
  .msg-panel{
    .msg-title{
      border-bottom: $--table-border;
      &-item{
        display: inline-block;
        margin: 12px 15px 0;
        height: 32px;
        cursor: pointer;
        font-size: 16px;
        &.isActive{
          color: $--color-primary;
          border-bottom: 2px solid  $--color-primary;
        }
      }
      &-item+&-item{
        margin-left: 30px;
      }
    }
    .msg-content{
      min-height: 290px;
      .msg-content-item + .msg-content-item{
        border-top: $--table-border;
      }
      &-item{
        display: flex;
        padding: 16px 15px;
        cursor: pointer;
        &:hover{
          background: $--color-primary-light-9;
          .main-title{
            color: $--color-primary;
          }
        }
        .img{
          width:32px;
          height: 32px;
          flex-shrink: 0;
          img{
            width: 100%;
            height: 100%;
          }
          ::v-deep .el-badge__content{
            border-width: 2px;
            right: 10px;
            width: 10px;
            height: 10px;
          }
        }
        .main{
          flex: 1;
          margin:0 8px;
          min-width: 0;
          .main-title{
            margin-bottom: 4px;
            &-type{
              display: inline-block;
              padding: 0 4px;
              margin-right: 4px;
              border: 1px solid $--color-danger;
              color:  $--color-danger;
              &.type-3{
                color: $--color-danger;
              }
              &.type-2{
                color: $--color-warning;
              }
              &.type-1{
                color: #EDBAB7;
              }
            }
          }
          .main-content{
            font-size: $--font-size-extra-small;
            color: $--color-text-placeholder;
          }
        }
        .time{
          flex-shrink: 0;
          font-size: $--font-size-extra-small;
          color: $--color-text-secondary;
        }
      }
      &-noContent{
        padding-top: 110px;
        text-align: center;
        .text{
          margin-top: 20px;
        }
      }
    }
    .msg-button{
      padding: 15px;
      text-align: center;
      cursor: pointer;
      color: $--color-primary;
      border-top: $--border-base;
    }
  }
</style>
