import app from './app'
import organ from './organ'
import user from './user'

export function registerSaaSModules(store, api) {
  const modules = { app, organ, user }

  Object.keys(modules).forEach(key => {
    if (!store?.hasModule(key)) store?.registerModule(key, modules[key](api))
  })
}
