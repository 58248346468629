
const url = location.origin.replace('http', 'ws') + '/ws/cec-message-server/ws'
let onlineSocket = null

// 连接
export function connectSocket() {
  if (onlineSocket) {
    return
  }
  onlineSocket = new WebSocket(url)
  onlineSocket.onopen = (msg) => {
    if (onlineSocket.readyState === 1) {
      intervalFn(() => onlineSocket.send('01'), 50000)
    }
  }
  onlineSocket.onmessage = (msg) => {
    console.log('onmessage: ', msg)
  }
  onlineSocket.onerror = (msg) => {
    console.log('onerror: ', msg)
  }
  onlineSocket.onclose = (msg) => {
    console.log('关闭websocket', msg)
  }
}

// 断开连接
export function destroySocket() {
  if (onlineSocket === null) return
  onlineSocket.close()
  onlineSocket = null
}

function intervalFn(fb = () => {}, timer) {
  if (typeof fb !== 'function') return
  fb()
  setTimeout(() => {
    intervalFn(fb, timer)
  }, timer)
}
