<script>
import { getAppType } from '../../sdk'
export default {
  name: 'WeixinAuth',
  beforeRouteEnter(to, from, next) {
    const { state, code } = to.query
    const params = {
      data: {
        code,
        accessType: 'pc',
        systemType: getAppType()
      }
    }
    next(async vm => {
      // 登录或绑定微信
      const authApi = code ? state === '/userProfile' ? vm.$api.bindWeixin : vm.$api.loginWeixin : null
      let path
      // 登录页重定向
      if (state !== '/userProfile') {
        const { redirect_uri } = JSON.parse(state)
        path = redirect_uri
      } else {
        // 账户设置页面
        path = state
      }
      try {
        if (authApi) {
          // 登录或绑定微信
          await authApi(params)
        }
        location.href = path
      } catch (error) {
        console.log(error)
        // 微信未绑定账号
        if (error?.message?.code === 'A0103') {
          vm.$router.replace(`/login/bind-weixin?id=${error.data}`)
        } else {
          location.href = path
        }
      }
    })
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
