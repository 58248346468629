import { addRoutes } from './libs/filterRoutes'
import MicroState from './libs/microState'
const loadApp = new Map()

export function resolveProps(props, { router: { router, asyncRoutes }, store }) {
  // 注册方法
  if (!window.__fn__) window.__fn__ = {}
  Object.assign(window.__fn__, { ...props.action, ...props })

  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) => {
        if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_ENV === 'staging') {
          console.log('%c [ 微应用全局对象 ] >', 'color: green', value.state)
          console.log('%c [ 状态修改 ] > window.__fn__.commitGlobalState(key, value)', 'color: green')
          console.log("%c [ eg ] > window.__fn__.commitGlobalState('user', { messageNotify: false })", 'color: green')
        }

        // 注册micro
        !store.hasModule('micro') && store.registerModule('micro', MicroState)

        // state存储
        const { state } = value
        store.commit('micro/SET_MICRO_STATE', state)

        // 避免重复添加权限路由，导致组件重载
        if (!loadApp.get(props.routerBase)) {
          // 添加权限路由（为微应用时路由根据主应用的菜单添加路由，其他时候添加全部路由）
          const { menus } = value?.state.user || null
          router && addRoutes(menus, router, asyncRoutes)
          loadApp.set(props.routerBase, true)
        }
      },
      true
    )
}

