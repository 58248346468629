/** 判断菜单是否分组展示key */
export const KEY_isMenuOnly = 'isOnly'

/** 机构id属性名 */
export const ORGAN_ID_KEY = 'organId'

/** 统一门户跳转参数key：应用机构id */
export const UNITY_QUERY_KEY_ORGAN = 'organId'

/** app框架全局属性 */
export const GLOBAL_APP_KEYS = {
  sdkVersion: 'sdkVersion'
}
