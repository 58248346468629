
/**
 * [{code:'a'},{code:'b',children:[{code:'c'}]}] => ['a','b','c']
 * @param {*} menus
 * @param {*} key
 * @returns
 */
function getKeys(menus, key) {
  let ret = []
  menus.forEach(menu => {
    ret.push(menu[key])

    if (menu.children) {
      ret = ret.concat(getKeys(menu.children, key))
    }
  })

  return ret
}

/**
 *
 * @param {*} menuRoutes
 * @param {*} keys
 * @returns
 */
function resolveMenusRoutes(menuRoutes = [], keys) {
  let ret = []
  menuRoutes.forEach(item => {
    const temp = { ...item }
    if (item?.component) {
      if (keys.indexOf(temp.name) > -1) {
        if (item?.children) temp.children = resolveMenusRoutes(item.children, keys)
        ret.push(temp)
      }
    } else {
      if (item.children) ret = [...ret, ...resolveMenusRoutes(item.children, keys)]
    }
  })
  return ret
}

/**
 *
 * @param {*} menus
 * @param {*} asyncRoutes
 * @returns
 */
export function getPermissionRoutes(menus, asyncRoutes) {
  if (!menus || !menus.length) {
    return []
  }

  let ret = []
  const keys = getKeys(menus, 'code')
  if (keys?.length) ret = resolveMenusRoutes(asyncRoutes, keys)
  return ret
}
/**
 *
 * @param {*} menus
 * @param {*} _router
 * @param {*} _asyncRoutes
 * @param {*} parentRoute 父级路由
 */
export function addRoutes(menus, _router = null, _asyncRoutes = [], parentRoute = null) {
  const routes = getPermissionRoutes(menus, _asyncRoutes)
  if (parentRoute) {
    parentRoute.children.push(...routes)
  }
  _router?.$addRoutes(parentRoute ? [parentRoute] : routes)
}
