import cloneDeep from 'lodash/cloneDeep'
import { STYLE_NORMAL } from 'shared/assets/CONST'

export default api => {
  const state = {
    organList: [], // 机构列表
    currentOrganID: '', // 当前机构id
    curOrganInfo: {}, // 当前机构信息
    organUserInfo: {}, // 机构用户信息
    organStyleInfo: STYLE_NORMAL() // 机构风格样式
  }

  const mutations = {
  // ========= 机构
    SET_ORGAN_LIST: (state, list) => {
      state.organList = list
    },
    SET_DEFAULT_ORGAN: (state, id) => {
      state.currentOrganID = id
      state.curOrganInfo = state.organList.find(o => o.id === id)
    },
    // 用户
    SET_USER_INFO: (state, data) => {
      state.organUserInfo = data
    },
    SET_ORGAN_STYLE_INFO(state, data) {
      if (!data.companyName) { data.companyName = state.curOrganInfo.name }
      if (!data.bannerColor) { data.bannerColor = STYLE_NORMAL().bannerColor }
      if (!data.textColor) { data.textColor = STYLE_NORMAL().textColor }
      state.organStyleInfo = { ...data }
    }
  }

  const getters = {
    curOrganName(state) {
      return state.organStyleInfo.companyAbbreviation || state.organStyleInfo.companyName || state.curOrganInfo.name
    }
  }

  const actions = {
    getOrganList({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api.current().then(async res => {
          const { data } = cloneDeep(res)
          const chooseOrgan = data?.find(d => d.choose)
          commit('SET_ORGAN_LIST', data)
          if (chooseOrgan?.id) {
            commit('SET_DEFAULT_ORGAN', chooseOrgan.id)
          } else {
            await dispatch('getOrganCurrent', data[0].id)
          }
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 指定默认机构
    async getOrganCurrent({ state, dispatch, commit }, id) {
      try {
        const { data } = await api.oneCurrent(id)
        commit('SET_DEFAULT_ORGAN', data)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    getOrganUserInfo({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api.getOrganUserInfo().then(res => {
          const { data } = cloneDeep(res)
          // v1.2调整
          if (data.account) {
            commit('user/SET_USER_INFO', { ...data, ...data.account }, { root: true })
          } else {
            dispatch('user/getUserInfo', null, { root: true })
          }
          commit('SET_USER_INFO', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    /** 机构切换 */
    chooseOrgan({ state, commit }, id) {
      api.switchCurrent(id).then(res => {
        location.href = '/'
      })
    },
    /** 获取机构风格设置 */
    async getOrganStyleInfo({ commit }) {
      try {
        const { data } = await api.getOrganStyleInfo()
        commit('SET_ORGAN_STYLE_INFO', data)
        return data
      } catch (error) {
        commit('SET_ORGAN_STYLE_INFO', {})
        return {}
      }
    }
  }

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
}
