import { Message } from 'element-ui'
import { getAppType } from './plat'
import { destroySocket } from './onlineSocket'

// 获取微应用编码 同qiankun路由匹配规则
export function getMicroCode() {
  return location.pathname.split('/')?.[1]
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export async function toLogin(path = '/login', _blank = false) {
  await sleep(1000)
  /** 断开在线连接 */
  destroySocket()
  /** 跳转登录页 */
  const href = `${path}?type=${getAppType()}&redirect_uri=${encodeURIComponent(location.toString())}`
  if (_blank) {
    window.open(href, '_blank')
  } else {
    location.href = href
  }
}

export async function asyncReload() {
  await sleep(1000)
  location.reload()
}

// 机构被切换，重新跳转
export async function asyncToIndex() {
  Message.info('机构已切换，正在切换中...')
  await sleep(1000)
  // 跳转第一个菜单
  location.href = '/'
}

export function isEmpty(val) {
  const type = Object.prototype.toString.call(val)
  const eValues = [undefined, null, '']
  if (type === '[object Array]') return val.length === 0
  if (type === '[object Object]') return Object.keys(val).length === 0
  return eValues.includes(val)
}
