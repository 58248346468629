<template>
  <section class="version-wrap">
    <div class="version-content">
      <header class="version-header">
        <h3>前端版本</h3>
      </header>
      <div class="version-list-box">
        <div v-for="(val, key) in frontVersions" :key="key" class="version-content inline inline-space">
          <div>{{ '- '+ key }}</div>
          <div>{{ val.version }}</div>
          <div>{{ val.date }}</div>
        </div>
      </div>

      <header class="version-header">
        <h3>服务版本</h3>
      </header>
      <div class="version-list-box">
        <el-scrollbar style="height: 100%">
          <ul class="vl-list">
            <li v-for="(val, key) in serverVersions" :key="key" class="vl-item">
              <div class="version-name">{{ key }}：</div>
              <div v-for="version in val" :key="version.instanceId" class="version-content inline inline-space">
                <div>{{ '- '+ version.serviceId }}</div>
                <div :class="[version.error && 'error']">{{ version.version || version.error || '版本未知' }}</div>
                <div>{{ version.timestamp }}</div>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
  </section>
</template>
<script>
import api from '@/api'
// import microApp from '@/micro/MicroApp'
import request from '@/utils/request'
import mianVersionInfo from '../../public/static/version.json'

export default {
  data() {
    return {
      frontVersions: {},
      serverVersions: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      this.getServiceVersions()
      this.getMicroVersions()
    },

    // ************** 数据请求
    getServiceVersions() {
      api.getProVersion().then(res => {
        this.serverVersions = res.data || {}
      })
    },

    async getMicroVersions() {
      const version = { [mianVersionInfo.name]: mianVersionInfo }

      const requests = this.$apps?.map(item => ({ apiUrl: item.entry + '/version.json' }))

      for (let index = 0; index < requests.length; index++) {
        const rq = requests[index]
        try {
          const res = await request({ withCredentials: false, url: rq.apiUrl })
          const key = res.name

          if (key) version[key] = res
        } catch (error) {
          console.log('[ error ] >', error)
        }
      }
      this.frontVersions = version
    }
  }
}
</script>

<style scoped lang="scss">

.version-wrap{
  padding: 20px;
}
// ====== common =======
.inline{
  display: flex;
  align-items: center;

  &-space{
    justify-content: space-between;

    div{
      flex: 1;
    }
  }
}
.error{
  color: $--color-danger;
}

ul,li{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// ====== version ========
.version-list-box{
  padding: 20px;
}
.version-header{
  height: 50px;
  line-height: 50px;
  padding:0 20px;

  background: $--background-color-base;
  color: $--color-text-primary;
  font-size: 14px;
}
.vl-item{
  margin:0 20px;
  padding: 20px 10px;
  border-bottom: $--border-base;

  .version-name{
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
  }
  .version-content{
    padding: 0 20px;
    line-height: 1.4;
    color: $--color-text-secondary;
  }
}
</style>
