<template>
  <div class="user-card ics-flex_center">
    <div v-loading="loading" class="user-card-wrap">
      <div class="user-card-title">{{ title }}</div>
      <div class="user-card-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    title: String,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
.user-card{
  background: $--background-color-base;
  height: 100vh;
  overflow: scroll;

  &-wrap{
    box-sizing: border-box;
    width: 900px;
    height: 804px;

    background: $--color-white;
    border-radius: 10px;
  }
  &-title{
    padding: 20px 0;
    text-align: center;

    font-size: $--font-size-extra-large;
    border-bottom: $--border-base;
  }
  &-content{
    padding: 20px 50px;
  }
}
</style>
