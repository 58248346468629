<template>
  <div class="ics-layout-header" :style="{background:organStyleInfo.bannerColor,color:organStyleInfo.textColor}">
    <div class="ics-layout-header__left saas-flex-width-0 ics-pointer" @click="$router.push('/')">
      <el-image :src="'/api'+organStyleInfo.logo" style="width:48px;height:48px;flex-shrink: 0;">
        <div slot="error">
          <img src="~shared/assets/defaultlogo.png" style="width:48px;height:48px">
        </div>
      </el-image>
      <span class="header-title saas-ellipsis">{{ curOrganName }}</span>
    </div>
    <div class="ics-layout-header__extra" />
    <organPopper class="header-right-item" />
    <component :is="bellComponentName" class="header-right-item" />
    <!-- <bellPopper class="header-right-item" /> -->
    <userPopper class="header-right-item" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import bellPopper from './components/bellPopper.vue'
import organPopper from './components/organPopper.vue'
import userPopper from './components/userPopper.vue'
import bellPopper_12 from './components/v1.2/bellPopper.vue'
import { getAPPGlobalConfig } from 'shared/sdk'
import { GLOBAL_APP_KEYS } from '@/CONST'

const bellPopperComponents = {
  bellPopper,
  'bellPopper1.2': bellPopper_12,
  bellPopper_latest: bellPopper_12
}

export default {
  name: 'SaaSHeader',
  components: {
    organPopper,
    userPopper,
    ...bellPopperComponents
  },
  computed: {
    ...mapState('organ', ['organStyleInfo']),
    ...mapGetters('organ', ['curOrganName']),
    bellComponentName() {
      const componentName = 'bellPopper'
      const sdkVersion = getAPPGlobalConfig(GLOBAL_APP_KEYS?.sdkVersion) || null
      const versionComponentName = componentName + sdkVersion
      // saas框架 || 应用框架高版本
      if (!GLOBAL_APP_KEYS || (sdkVersion && !bellPopperComponents[versionComponentName])) {
        return componentName + '_latest'
      } else if (bellPopperComponents[versionComponentName]) {
        return versionComponentName
      }
      return componentName
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title{
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
}

.header-right-item + .header-right-item{
  margin-left: 40px;
}
// LOGO
::v-deep .ics-layout-header__left{
  img{
    width: auto;
  }
}

</style>
