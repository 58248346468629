import { getMicroApp } from './libs/MicroApp'

export default function microPlugin(initGlobalState) {
  const microApp = getMicroApp(initGlobalState)
  return store => {
    microApp?.microState.onGlobalStateChange((value, pre) => {
      if (JSON.stringify(value.state) !== JSON.stringify(store.state)) {
        // console.log('%c [ main GlobalStateChange ] >', 'color: orange', value)
        store.commit('SET_GLOBAL_STATE', value.state)
      }
    })

    store.subscribe((mutations, state) => {
      // console.log('%c [ main subscribe ] >', 'color: orange', mutations.type, state)
      if (mutations.type !== 'SET_GLOBAL_STATE') {
        microApp.commitGlobalState(state)
      }
    })
  }
}

