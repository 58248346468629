<!-- eslint-disable vue/no-unused-vars -->
<template>
  <section v-loading="loading" class="table-page">
    <section class="content-table">
      <el-table
        :data="tableData"
        class="table-page"
      >
        <el-table-column v-for="item in msgConfig" :key="item.props">
          <!-- title -->
          <template slot="header" slot-scope="{}">
            <el-checkbox
              v-model="headerData[item.titleProps]"
              :indeterminate="headerData[item.titleIndeterminate]"
              :disabled="item.disableProps && !headerData[item.disableProps]"
              @change="checkHeadChange(headerData[item.titleProps], item.titleProps)"
            />
            <span style="margin: 0 7px">{{ item.title }}</span>
            <!-- tips -->
            <el-popover
              v-if="item.tips"
              placement="top-start"
              trigger="hover"
              :content="item.titleTips"
            >
              <i slot="reference" class="el-icon-warning-outline" />
            </el-popover>
          </template>
          <!-- content -->
          <template slot-scope="{row,$index}">
            <el-checkbox
              v-model="row[item.contentProps]"
              :disabled="item.disableProps && !headerData[item.disableProps]"
              :indeterminate="row[item.contentIndeterminate]"
              @change="checkChange(row[item.contentProps],item.contentProps,$index)"
            />
            <span class="text">{{ row[item.contentSpan] }}</span>
            <el-popover
              v-if="item.contentTips"
              placement="top-start"
              trigger="hover"
              :content="row[item.contentTips]"
            >
              <i slot="reference" class="el-icon-warning-outline" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="content-pagination">
      <el-pagination
        class="saas-pagination"
        layout="total,prev, pager, next,sizes ,jumper"
        background
        :page-size="searchForm.pageSize"
        :current-page="searchForm.pageNum"
        :total="searchForm.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </section>
  </section>
</template>

<script>
const msgConfig = [
  {
    title: '消息类型',
    titleProps: 'checked_all',
    titleIndeterminate: 'has_checked',
    contentProps: 'checked_line',
    contentIndeterminate: 'isIndeterminate',
    contentSpan: 'typeName',
    contentTips: 'description'
  }, {
    title: '站内信（含App）',
    titleProps: 'isAllSiteMsg',
    titleIndeterminate: 'hasSiteMsg',
    contentProps: 'isSiteMsg',
    contentSpan: 'mail'
  }, {
    title: '邮箱',
    titleProps: 'isAllEmail',
    titleIndeterminate: 'hasEmail',
    contentProps: 'isEmail',
    disableProps: 'email',
    contentSpan: 'mailbox'
  }, {
    title: '短信',
    titleProps: 'isAllSms',
    titleIndeterminate: 'hasSms',
    contentProps: 'isSms',
    contentSpan: 'shortMessage'
  }, {
    title: 'Webhook',
    titleProps: 'isAllWebhook',
    titleIndeterminate: 'hasWebhook',
    titleTips: '可以配置钉钉和微信公众号推送，需提前关注公众号，需提前配置相应推送地址',
    contentProps: 'isWebhook',
    disableProps: 'webhook',
    contentSpan: 'Webhook'
  }]
export default {
  name: 'AcceptSetting',
  data() {
    return {
      msgConfig,
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      headerData: {
        checked_all: false,
        has_checked: false,
        isAllEmail: false, // 邮箱当前列是否全选
        isAllSiteMsg: false,
        isAllSms: false,
        isAllWebhook: false,
        hasSiteMsg: false, // 当前列是否为“不确定状态”, 选中行数 >=1 && <all
        hasSms: false,
        hasEmail: false,
        hasWebhook: false
      },
      loading: false
    }
  },
  created() {
  },

  mounted() {
    this.initData(true)
  },

  methods: {
    async initData(init) {
      try {
        await this.getHeaderData()
      } catch (error) {
        throw new Error('查询数据异常')
      }
      this.getData(init)
    },
    getHeaderData() {
      this.$api.getAllInfo({ data: {}}).then(res => {
        res = res.data
        this.headerData = res
        this.headerData.checked_all = (res.email ? res.isAllEmail : true) && (res.webhook ? res.isAllWebhook : true) && res.isAllSiteMsg && res.isAllSms
        this.headerData.has_checked = !this.headerData.checked_all && (res.isAllEmail || res.isAllSiteMsg || res.isAllSms || res.isAllWebhook || res.hasSiteMsg || res.hasSms || res.hasEmail || res.hasWebhook)
      })
    },
    getData(init) {
      if (init) {
        this.searchForm.pageNum = 1
      }
      this.loading = true
      this.$api.getMessageList(this.$formatParams(this.searchForm)).then(res => {
        const { records, total } = res.data
        this.tableData = records
        this.searchForm.total = total
        // checked_line:选中一行    isIndeterminate:没有选中一行
        this.tableData.map(el => {
          el.checked_line = (el.email ? el.isEmail : true) && (el.webhook ? el.isWebhook : true) && el.isSiteMsg && el.isSms
          el.isIndeterminate = !el.checked_line && (el.isEmail || el.isSiteMsg || el.isSms || el.isWebhook)
        })
      }).finally(() => {
        this.loading = false
      })
    },
    handleCurrentChange(val) {
      this.searchForm.pageNum = val
      this.initData(false)
    },
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      if (this.maxNum < this.searchForm.pageNum) {
        this.searchForm.pageNum = this.maxNum
      }
      this.initData()
    },
    // 保存一个单元格
    saveNode(index, attr, checked) {
      const el = this.tableData[index]
      const params = {
        typeId: el.typeId,
        isWebhook: '',
        isEmail: '',
        isSiteMsg: '',
        isSms: ''
      }
      params[attr] = checked
      this.saveData(params)
    },
    // 保存一行
    saveLine(index, attr, checked) {
      const el = this.tableData[index]
      // console.log(el)
      const params = {
        typeId: el.typeId,
        isWebhook: el.webhook ? checked : false,
        isEmail: el.email ? checked : false,
        isSiteMsg: checked,
        isSms: checked
      }
      this.saveData(params)
    },
    // 保存一列
    saveColumn(checked, attr) {
      const params = {}
      params[attr] = checked
      this.saveData(params)
    },
    // 全部保存或取消
    saveAll(checked, attr) {
      const params = {
        isAllSiteMsg: checked,
        isAllSms: checked,
        isAllEmail: checked,
        isAllWebhook: checked
      }
      this.saveData(params)
    },
    saveData(params) {
      this.loading = true
      this.$api.saveMessageRece({ data: params }).then(res => {
        this.initData()
        this.$message({
          type: 'success',
          message: '保存成功',
          duration: 1000
        })
      }).finally(() => {
        this.loading = false
      })
    },
    // 单选/取消
    checkChange(checked, attr, index) {
      // console.log(index, attr, checked)
      if (attr === 'checked_line') {
        this.saveLine(index, attr, checked)
      } else {
        this.saveNode(index, attr, checked)
      }
    },
    // 全选/取消
    checkHeadChange(checked, attr) {
      if (attr === 'checked_all') {
        this.saveAll(checked, attr)
      } else {
        this.saveColumn(checked, attr)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.table-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding-bottom: 20px;
  overflow: hidden;
  ::v-deep {
    .el-button--text {
      // color: $saas-main-blue;
      padding: 0 !important;
    }
    .el-button + .el-button {
      margin-left: 15px;
    }
  }
}
.content-table {
  flex: 1;
  overflow: hidden;
  .el-table ::v-deep .disabledSelection > .cell {
    padding: 0;
    height: 14px;
    width: 14px;
    background-color: #edf2fc;
    border: 1px solid #DCDFE6 !important;
    border-radius: 2px;
    cursor: not-allowed;
    .el-checkbox {
      display: none;
    }
  }
}
.content-pagination {
  flex-shrink: 0;
  padding: 20px 0;
  .el-pagination {
    text-align: right
  }
}
::v-deep .text{
  margin-left: 7px
}
</style>
