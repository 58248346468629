<template>
  <div id="app">
    <!-- 部分/container-app/开头全屏页面 -->
    <div v-if="isMicroFull" id="frame" />
    <!-- 默认框架布局、框架页面 -->
    <router-view v-else />
    <AuthDialog v-if="isShowAuthDialog" :api="api" />
  </div>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      api
    }
  },
  computed: {
    ...mapState('app', ['isShowAuthDialog']),
    isMicroFull() {
      return this.$route.path.includes('/container-app/') || this.$route.path.includes('_ca/') /* || this.$route.path.startsWith('/login')*/
    }
  },
  mounted() {
    /** 获取系统logo */
    this.$store.dispatch('app/getAppLogo')
  },
  methods: {}
}
</script>

<style lang="scss">
</style>
