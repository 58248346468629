// 解析应用数据
export function resolveApps(apps) {
  if (!apps) return []

  if (!Array.isArray(apps)) {
    apps = [apps]
  }

  const qiankunApps = apps?.map(item => {
    const { name, homePage, agentId } = item
    return {
      name,
      entry: window?.global_config?.microUrl?.concat(agentId + '/') || homePage,
      container: agentId?.endsWith('_ca') ? '#app' : '#frame', // 规定应用编码添加 _ca 后缀的应用自定义布局
      activeRule: '/' + agentId
    }
  })

  return qiankunApps?.filter(item => item.entry) || []
}
