<template>
  <el-popover
    v-model="popperVisible"
    popper-class="saas-popper-wrap layout-user-popper"
    class="layout-user"
    placement="bottom-end"
    :width="100"
    v-bind="popoverConfig"
  >
    <el-image
      slot="reference"
      class="layout-user-img saas-pointer"
      :src="userSrc"
      alt="头像"
      fit="cover"
    >
      <img slot="error" class="layout-user-img" src="~shared/assets/avatar.png" alt="">
    </el-image>
    <div class="layout-user-content">
      <div
        v-for="item in dropdownMenus"
        :key="item.id"
        class="layout-user-item saas-popper-item saas-pointer saas-hover-bg saas-hover-text"
        @click="handleClick(item)"
      >{{ item.name }}</div>
    </div>
  </el-popover>
</template>

<script>
import { mapState } from 'vuex'
import avatar from 'shared/assets/avatar.png'
import { popoverConfig, dropdownMenus } from './config'

export default {
  name: 'UserPopper',
  data() {
    return {
      popperVisible: false,
      popoverConfig
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'menus']),
    ...mapState('app', ['deployConfig']),
    userSrc() {
      if (this.userInfo.headimg) return '/api' + this.userInfo.headimg
      return avatar
    },
    dropdownMenus() {
      let _dropdownMenus = [...dropdownMenus(this.deployConfig, this)]

      // 头像下拉菜单/按钮权限处理
      const avatorMenus = this.menus.find(m => m.code === 'avatorPopper') || []

      // 平台自定义配置下拉菜单
      const props_dropdownList = this?.$parent?.$attrs?.dropdownList
      if (props_dropdownList && typeof props_dropdownList === 'function') {
        _dropdownMenus = this?.$parent?.$attrs.dropdownList(_dropdownMenus)
      }

      const ret = _dropdownMenus.filter(m => {
        const isPermission = !m.permission || (m.permission && avatorMenus?.buttonPermission?.includes(m.permission))
        const isShow = !m.isShow || (m.isShow && m.isShow())
        return isPermission && isShow
      })
      return ret
    }
  },
  methods: {
    handleClick(val) {
      this.popperVisible = false
      if (val.store) {
        this.$store.dispatch(val.store)
      } else {
        // 兼容第三方应用跳转
        const isThirdPlat = window?.__SAAS_APP_CONFIG__?.unityHomePage || ''
        const path = isThirdPlat.concat(this.$router.resolve({ path: val.id }).href)
        if (val.isRoute) {
          this.$router.push(path)
        } if (val.fn && typeof val.fn === 'function') {
          val.fn(path)
        } else {
          window.open(path, '_blank')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.layout-user{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  &-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &-item{
    text-align: center;
    border-bottom: $--border-base;
  }
}

</style>

<style lang="scss">
  .layout-user-popper{
    min-width: 100px !important;
  }
</style>
