import { OpenDevStorage } from '@/setting'

// 本地数据存储/获取
export function localStorageChange(name, value, isCache = OpenDevStorage) {
  if (isCache) {
    name = '__dev__' + name
    const val = localStorage.getItem(name)
    if (!val) {
      localStorage.setItem(name, JSON.stringify(value))
    } else {
      value = JSON.parse(val)
    }
  }

  return value
}

// 本地数据存储/获取
export function sessionStorageChange(name, value, isCache = OpenDevStorage) {
  if (isCache) {
    name = '__dev__' + name
    const val = sessionStorage.getItem(name)
    if (!val) {
      sessionStorage.setItem(name, JSON.stringify(value))
    } else {
      value = JSON.parse(val)
    }
  }

  return value
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function routerToMenus(routes, appCode = '') {
  const preUrl = appCode ? '/' + appCode : ''
  const ret = []
  routes?.forEach(route => {
    const temp = { ...route }
    const { name, meta, path } = route
    if (route.children) temp.children = routerToMenus(temp.children, appCode)
    ret.push({
      code: name,
      name: meta?.title || '',
      resourceType: 'pc',
      extra: { data: JSON.stringify({ path: preUrl + path, icon: '', ...meta }) },
      children: temp.children
    })
  })
  return ret
}

export const R2M = routerToMenus

export * from './request'
export * from './has'
export { redirectRouter } from './redirectRouter'
