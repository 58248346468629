<template>
  <el-button
    class="phone-code"
    type="primary"
    :disabled="disabled"
    :loading="codeLoading"
    v-bind="$attrs"
    @click="getCode"
  >{{ codeText }}</el-button>
</template>

<script>
export default {
  props: {
    phone: String,
    beforeRequest: Function,
    api: Function
  },
  data() {
    return {
      disabled: false,
      codeLoading: false,
      codeText: '获取验证码'
    }
  },
  methods: {
    async getCode() {
      // 请求前提操作
      if (this.beforeRequest) {
        if (this.beforeRequest.then) {
          await this.beforeRequest()
        } else {
          this.beforeRequest()
        }
      }
      this.codeLoading = true
      this.api(this.phone).then(_ => {
        this.disabled = true
        this.codeText = 60
        const interval = setInterval(() => {
          this.codeText--
          if (this.codeText <= 0) {
            clearInterval(interval)
            this.disabled = false
            this.codeText = '获取验证码'
          }
        }, 1000)
      }).finally(() => {
        this.codeLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-code{
  margin-left: 16px;
}
</style>
