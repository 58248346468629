<template>
  <div class="style-set-wrap">
    <div class="style-set-header">风格设置</div>
    <div class="style-set-content">
      <div>
        <el-form ref="ruleForm" label-width="124px" label-suffix=":" :model="form">
          <el-form-item v-for="(item,index) in formOptions" :key="index" v-bind="item" :rules="item.rules">
            <!-- upload -->
            <template v-if="item.type==='upload'">
              <div class="sc-upload-box">
                <el-upload
                  class="sc-upload-main"
                  :action="$api.upload"
                  :disabled="imageLoading"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="res =>form.logo = res.data"
                  :on-change="handleUploadChange"
                  :on-error="()=>$message.error('logo上传失败')"
                >
                  <div v-if="imageLoading" class="sc-upload-item progress">
                    <el-progress type="circle" :percentage="imageLoadingPercentage" :width="68" />
                  </div>
                  <el-image v-else :src="'/api'+form.logo" class="sc-upload-item img">
                    <img slot="error" src="~shared/assets/defaultlogo.png" width="88" height="88" :style="{backgroundColor: form.bannerColor || ''}">
                  </el-image>
                </el-upload>
                <span class="btn-recover sc-upload-operator" @click="setDefaultData('logo')">恢复默认</span>
              </div>
              <span class="ics-color-danger">注：建议上传240*240px以上、120kb以内的图标</span>
            </template>
            <!-- 通用 -->
            <div v-else class="saas-flex-align-center">
              <el-input v-if="item.type === 'input'" v-model="form[item.prop]" v-bind="item.typeOptions" />
              <el-color-picker v-else-if="item.type === 'color-picker'" v-model="form[item.prop]" size="medium" />
              <span v-if="item.canRecover" class="btn-recover" @click="setDefaultData(item.prop)">恢复默认</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSaveTheme">应用</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { formOptions } from './options'
import { STYLE_NORMAL } from 'shared/assets/CONST'

let interval = null
export default {
  name: 'StyleSet',
  data() {
    return {
      formOptions,
      form: {},
      imageUrl: '',
      isUploadRight: false,
      imageLoading: false,
      imageLoadingPercentage: 0,
      rules: {
        logo: [{ required: true }],
        bannerColor: [{ required: true }],
        textColor: [{ required: true }]
      }
    }
  },
  computed: {
    ...mapState('organ', ['organStyleInfo', 'curOrganInfo'])
  },
  watch: {
    isUploadRight(nv, ov) {
      if (nv) {
        this.isUploadRight = false
        this.imageLoadingPercentage = 0
        this.imageLoading = true
        if (interval) clearInterval(interval)
        const num = 30
        interval = setInterval(() => {
          if (this.imageLoadingPercentage < 99) {
            if (this.imageLoadingPercentage < 100 - num) {
              this.imageLoadingPercentage += Math.floor(Math.random() * num)
            } else {
              this.imageLoadingPercentage += Math.floor(Math.random() * 2)
            }
          }
        }, 200)
      }
    }
  },
  created() {
    this.setDefaultData()
  },
  methods: {
    ...mapMutations('organ', ['SET_ORGAN_STYLE_INFO']),
    // 恢复默认
    setDefaultData(key) {
      const defaultStyle = STYLE_NORMAL()
      if (key === undefined) {
        this.form = { ...this.organStyleInfo }
      } else if (defaultStyle[key] !== undefined) {
        this.form[key] = defaultStyle[key]
      }
    },
    // 应用
    handleSaveTheme() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api.saveOrganStyleInfo({ data: { ...this.form }}).then(res => {
            this.$message.success('应用成功')
            this.SET_ORGAN_STYLE_INFO({ ...this.form })
          })
        }
      })
    },
    // logo上传前校验
    beforeUpload(file) {
      const isJPG = ['image/jpeg', 'image/png'].includes(file.type)
      const isLt120k = file.size / 1024 / 1024 / 1024 < 120

      if (!isJPG) {
        this.$message.error('LOGO只能是 JPG、PNG 格式!')
      }
      if (!isLt120k) {
        this.$message.error('上传头像图片大小不能超过 120kb!')
      }
      this.isUploadRight = isJPG && isLt120k
      return this.isUploadRight
    },
    // logo上传loading
    handleUploadChange(file, fileList) {
      if (file.status !== 'ready') {
        clearInterval(interval)
        this.imageLoadingPercentage = 100
        setTimeout(() => {
          this.imageLoading = false
        }, 100)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
$namespace:'.style-set';
$img-width:88px;

#{$namespace}-wrap{
  width:900px;
  margin: 120px auto 0;
  background: $--color-white;
  border-radius: 10px;
}

#{$namespace}-header{
  height: 64px;
  line-height: 64px;
  text-align:center;
  font-size: 20px;
  border-bottom: $--border-base;
}

#{$namespace}-content{
  margin-top: 60px;
  margin-left:180px;
  width: 464px;
  height: 600px;
  .btn-recover{
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    color: $--color-primary;
  }
}

.sc-upload-box{
  display: flex;
  .sc-upload-main{
    width: $img-width;
    height: $img-width;
  }
  .sc-upload-item {
    display: block;
    width: $img-width;
    height: $img-width;
    &.img{
      object-fit: cover;
    }
    &.progress{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: $--border-base;
    }
  }

  .sc-upload-icon {
    justify-self: center;
    font-size: 18px;
    width: $img-width;
    aspect-ratio: 1/1;
    line-height: $img-width;
    border: $--border-base;
    border-radius: 6px;
    &:hover{
      border-color: $--color-primary;
    }
  }
  .sc-upload-operator{
    align-self: flex-end;
  }
}
::v-deep .el-form-item{
  margin-bottom: 20px;
}

</style>
