<template>
  <el-dialog
    title="上传头像"
    custom-class="commponents-dialog"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="650px"
  >
    <el-upload
      class="avatar-uploader"
      :action="uploadUrl"
      :show-file-list="false"
      :limit="1"
      :file-list="fileList"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon" />
      <div slot="tip" class="el-upload__tip">支持扩展名：jpg、jpeg、png、gif；大小不超过5MB</div>
    </el-upload>
    <div class="upload-title">上传图片</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'UserProfileUpload',
  data() {
    return {
      dialogVisible: false,
      loading: false,
      imageUrl: '',
      fileList: [],
      form: {
        imgPath: ''
      },
      uploadUrl: this.$api.upload
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.imageUrl = ''
        this.form.imgPath = ''
        this.fileList = []
        this.loading = false
      }
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.form.imgPath = res.data
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpg'
      const isJPEG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isGIF = file.type === 'image/gif'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isJPG && !isJPEG && !isPNG && !isGIF) {
        this.$message.error('上传头像图片只能是 jpg、jpeg、png、gif 格式!')
      } else if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      return (isJPG || isJPEG || isPNG || isGIF) && isLt5M
    },
    save() {
      if (!this.imageUrl) {
        this.$message.info('请选择图片')
        return
      }
      this.loading = true
      this.$api.updateUserHeadImage({ data: this.form.imgPath })
        .then((res) => {
          this.$emit('updateInfo')
          this.$message.success('保存成功')
          this.dialogVisible = false
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding: 20px 50px 10px 20px !important;
    position: relative;
}
.upload-title{
    position: absolute;
    top: 22px;
    left: 130px;
}
.avatar-uploader{
  text-align: center;
}
 .avatar-uploader ::v-deep  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
