<template>
  <div class="error-page">
    <div class="error-page-content">
      <img src="~shared/assets/no_service.png" alt="">
      <p class="error-page-content--tips">{{ tips }}</p>
      <el-button v-if="serviceInfo.admin" type="primary" @click="TRIGGER_AUTH_DIALOG(true)">延长期限</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'NoAuth',
  computed: {
    ...mapState('app', ['serviceInfo']),
    tips() {
      let text
      if (this.serviceInfo.admin) {
        text = '您的企业服务已到期，请及时联系我们延长使用期限。'
      } else {
        text = '您的企业服务已到期，请及时联系管理员延长使用期限。'
      }

      return text
    }
  },
  methods: {
    ...mapMutations('app', ['TRIGGER_AUTH_DIALOG'])
  }

}
</script>

<style scoped lang="scss">
.error-page{
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  &-content {
    padding-top: 10%;
    text-align: center;
    &--tips {
      padding: 15px 0 20px 0;
      color:$--color-text-regular;
    }
  }
}
</style>
