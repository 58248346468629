import cloneDeep from 'lodash/cloneDeep'
import api from '@/api'
import { sessionStorageChange } from '@/utils'
import { resolveSources } from 'saas-shared'

const state = {
  userInfo: {}, // 用户信息
  menus: [], // 菜单
  menusSource: [],
  menusPre: [], // 展示菜单，当项目中存在isOnly属性菜单时生效
  organList: [], // 机构信息
  currentOrganID: '' // 当前机构id
}

const mutations = {
  SET_PRE_MENUS: (state, data) => {
    state.menusPre = data
  },
  // 用户
  SET_USER_INFO: (state, data) => {
    state.userInfo = data
  },
  SET_MENUS: (state, data) => {
    state.menusSource = data
    state.menus = resolveSources(data)
  },
  // ========= 机构
  SET_ORGAN_LIST: (state, list) => {
    state.organList = list
  },
  SET_DEFAULT_ORGAN: (state, id) => {
    state.currentOrganID = id
  }
}

const actions = {
  // 跳转请求授权地址
  async toAuth() {
    sessionStorage.setItem('historyURL', location.href)
    const { data } = await api.getAuthURL()
    window.location.href = data
  },

  // code登录
  async codeLogin({ state }, code) {
    await api.loginWithCode(code)
    const toURL = sessionStorage.getItem('historyURL')
    return toURL
  },
  // 退出
  logout() {
    return new Promise((resolve, reject) => {
      api.loginOut().then(res => {
        // 重新加载页面
        location.reload()
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户信息
  getUserInfo({ state, commit }) {
    return new Promise((resolve, reject) => {
      api.getUserInfo().then(res => {
        const { data } = cloneDeep(res)
        commit('SET_USER_INFO', data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 获取基础应用资源
  getMenus({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      api.getSource().then(res => {
        const { data } = cloneDeep(res) || []

        // 开发环境 缓存配置
        const menus = sessionStorageChange('menus', data)

        commit('SET_MENUS', menus)
        resolve(menus)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
