const state = {}

const getters = {
  getMicroState: state => {
    return key => state[key] || {}
  }
}

const mutations = {
  SET_MICRO_STATE: (state, params) => {
    if (!params || JSON.stringify(params) === '{}' || JSON.stringify(params) === '[]') return
    let microStates = params

    if (params instanceof Array) {
      const [key, data] = params
      const [parent, child] = key?.split('/')
      microStates = child ? { [parent]: { [child]: data }} : { [parent]: data }
    }

    for (const key in microStates) {
      if (Object.hasOwnProperty.call(microStates, key)) {
        const micState = microStates[key]

        if (!(micState instanceof Object)) {
          console.error(`[SET_MICRO_STATE] the value ${micState} of ${key} needs a object`)
          return
        }
        if (JSON.stringify(micState) !== JSON.stringify(state[key])) {
          state[key] = { ...state[key], ...micState }
        }
      }
    }
  }
}

export default {
  namespaced: true,
  getters,
  mutations,
  state
}
