<script>
export default {
  name: 'Redirect',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$router.replace(from.path)
    })
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
