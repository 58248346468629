import 'core-js' // promise兼容
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { R2M, routerToMenus } from './utils'
window.R2M = R2M
window.routerToMenus = routerToMenus
import SaaSUI from 'saas-shared'
import api from '@/api'

import './plugins'
import './router/routerGuard'
// import 'saas-shared/lib/saas-shared.css'
Vue.use(SaaSUI, { api })

if (process.env.VUE_APP_ENV === 'staging') {
  const style = 'color:#fff; background:#59BC13; padding:1px; border-radius:4px;'
  console.log('%c ------------开发初始化配置-----------', style)
  console.log('%c 关联本地项目 \n Application -> __dev__app -> 修改homepage为本地服务地址 \n\n 初始化菜单 \n 1、使用 window.R2M(routes,appCode) 获取菜单数据，\n 2、Application -> __dev__menus -> 替换数据', 'color:#3265DE;')
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
