<template>
  <div v-if="curOrganName" class="organ-popper saas-pointer">
    <el-popover
      v-model="organVisible"
      placement="bottom-end"
      :width="308"
      :offset="10"
      popper-class="saas-popper-wrap layout-organ-popper"
      v-bind="popoverConfig"
    >
      <span slot="reference" class="organ-popper--reference saas-flex-space_between">
        <span class="saas-ellipsis">{{ curOrganName }}</span>
        <i class="el-icon-caret-bottom icon-right" />
      </span>

      <div
        :class="[
          'organ-popper-item',
          'saas-flex-space_between',
          'saas-pointer',
          'saas-hover-bg',
          'active'
        ]"
      >
        <span class="organ-popper-item--span">{{ curOrganName }}</span>
        <i class="el-icon-check" />
      </div>

      <div
        v-for="item in sortOrganList"
        :key="item.id"
        :class="[
          'organ-popper-item',
          'saas-flex-space_between',
          'saas-pointer',
          'saas-hover-bg',
        ]"
        @click="chooseOrganItem(item.id)"
      >
        <span class="organ-popper-item--span">{{ item.name }}</span>
      </div>
    </el-popover>
    <!-- 切换后台 -->
    <div class="organ-popper-button">
      <span v-if="isShowToBack" @click="toOrgan">切换后台</span>
      <span v-else @click="toUnity">切换门户</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { getCurPlat } from 'shared/sdk'
import { popoverConfig } from './config'

export default {
  name: 'OrganPopper',
  data() {
    return {
      organVisible: false,
      popoverConfig
    }
  },
  computed: {
    ...mapState('organ', [
      'organList',
      'currentOrganID',
      'curOrganInfo',
      'organStyleInfo'
    ]),
    ...mapState('user', ['menusSource']),
    ...mapGetters('organ', ['curOrganName']),
    // curOrganName() {
    //   return this.organStyleInfo.companyAbbreviation || this.organStyleInfo.companyName || this.curOrganInfo.name
    // },
    sortOrganList() {
      const list = cloneDeep(this.organList)
      const index = list.findIndex(item => item.id === this.currentOrganID)
      const ret = list.splice(index, 1)
      // return ret.concat(list)
      console.log(ret)
      return list
    },
    isShowToBack() {
      return getCurPlat() === 'unity'
    },
    organURL() {
      return this.menusSource.filter(m => m.code === 'organURL')?.[0]?.extra?.path || ''
    }
  },
  methods: {
    ...mapActions('organ', ['chooseOrgan']),
    chooseOrganItem(id) {
      this.organVisible = false
      if (id === this.currentOrganID) return
      this.chooseOrgan(id)
    },
    toOrgan() {
      location.href = this.organURL
    },
    toUnity() {
      location.href = window.global_config.unityUrl || window?.__SAAS_APP_CONFIG__?.unityHomePage
    }
  }
}
</script>

<style lang="scss" scoped>
  .organ-popper{
    display: flex;
    align-items: center;
    font-size: $--font-size-base ;
    flex-shrink: 0;
    &--reference{
      max-width: 240px;
      .icon-right{
        padding-left: 10px;
      }
    }
    &-item{
      padding: 15px;
      border-bottom: $--border-base;
      &--span{
        max-width: 224px;
      }
      &.active{
        color: $--color-primary;
      }
    }
    &-button{
      font-size: $--font-size-extra-small;
      padding:7px 15px;
      margin-left: 10px;
      border: 1px solid rgba(#fff,.2);
      border-radius: 2px;
    }
  }
</style>
<style lang="scss">
  .layout-organ-popper{
    max-height: 450px;
    overflow: auto;
    .popper__arrow{
      left: 286px !important;  //308(面板宽度)-12(箭头宽度)-10(偏移量)
      right: 10px !important;
    }
  }
</style>
