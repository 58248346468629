<template>
  <SaaSLayout
    ref="layout"
    :menus="menusPre"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  data() {
    return { }
  },
  computed: {
    ...mapState('user', ['menus', 'menusPre']),
    ...mapState('app', ['appInfo'])
  },

  async mounted() {
    this.$nextTick(_ => {
      const { addTagView, delTagView } = this.$refs.layout.getEl()

      window.__fn__ = window?.__fn__ || {}
      Object.assign(window.__fn__, { addTagView, delTagView })
    })
  }
}
</script>

<style>

</style>
