<!--  -->
<template>
  <div class="">
    <el-button @click="$emit('showInfo')">返回</el-button>
    <div class="title">{{ detailData.title }}</div>
    <div class="time">{{ detailData.createTime }}</div>
    <hr style="opacity: 0.5;">
    <div class="content">{{ detailData.content }}</div>
  </div>
</template>

<script>
export default {
  name: 'MessageNotifyDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },

  mounted() {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.title{
    font-size: 30px;
    text-align: center;
}
.time{
    text-align: center;
    height: 40px;
    line-height: 40px;
}
.content{
    padding: 0 20px;
    font-size: 16px;
    text-indent: 2em;
    word-break: break-all;
    font-weight: normal;
    line-height: 40px;
    color: #343232;
}
</style>
