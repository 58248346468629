export default {
  // 公司简介
  companyProfile: [
    '上海中电电子系统科技股份有限公司原隶属于“中国电子”，创建于1984年。',
    '公司是服务于智慧城市、智慧环控、智慧云物联、智慧运维等领域的高新技术企业，也是物联网整体解决方案提供商。',
    '司已取得十余项国家级资质，拥有自主研发的“E维”系列核心产品，是上海市企业技术中心、长宁区小巨人企业、专精特新企业。',
    '我们传承了老一代“电子人”的“专注与奉献”精神，致力于“创新服务，实现梦想”并坚信“我们的服务可以改变世界”。'
  ],
  // 董事长致辞
  companyProfile2: [
    '公司愿景：我们的服务可以改变世界',
    '企业使命：创新服务实现梦想',
    '经营理念：诚信·务实·精益·创新',
    '企业精神：以人为本·客户至上·追求卓越·团队合作'
  ],
  // 组织架构
  messageFromTheChairman: [
    '客户的信任、团队的协同以及创新服务实现梦想的企业使命让上海中电在过去的岁月里不断的蜕变、进化。',
    '我们相信服务可以改变世界，让世界变得更加美好，并努力在理解客户梦想、价值观的基础上提供最优的产品和服务。我们明白助力实现客户的梦想其实就是在积累自己梦想实现的元素。',
    '我们尊重人才、鼓励天马行空的想象力，但也注重产品和服务的细枝末节；我们愿意和员工分享成功的喜悦，更看重失败给与我们的启发；我们积极践行企业的社会责任，关心员工的幸福，也把我们的收获分享给那些需要帮助的人。',
    '我们不断的更新自我，努力通过每一次的创新与变革，让我们更加接近梦想；服务正在改变世界，也在改变你我，上海中电将把最优的产品和服务带到您的身边，并与您一起分享彼此梦想实现时的喜悦！'
  ],
  // 发展历程
  developmentPath: [
    '2015年06月    ——     杭州分公司喜迁新办公楼',
    '2014年11月    ——     承接华数白马湖数字电视产业园机房项目，是公司IDC 业务的新起点',
    '2014年09月    ——     通过上海市科委高新企业认证',
    '2014年08月    ——     公司自主研发的IBMS中标万达慧云系统4个标段',
    '2014年07月    ——     公司2014-2018年战略规划纲要发布',
    '2014年03月    ——     承接上海商用飞机制造有限公司大客研保综合保障设施行政办公楼，标志公司进入航空行业',
    '2013年11月    ——     承接南京“青奥会”会议系统工程',
    '2012年12月    ——     公司增资至5018万',
    '2012年07月    ——     中标上海浦东发展银行合肥综合中心弱电智能化系统项目，实现公司与金融系统大型数据中心项目的合作',
    '2011年12月    ——     以联合体方式中标成都金控金融后台服务中心项目施工总承包，中标额5.95亿元',
    '2010年06月    ——     公司迁入上海漕河泾开发区自购的办公楼',
    '2010年03月    ——     承接北京师范大学能耗监测及控制平台项目，该项目是财政部、教育部、住房和城乡建设部节约型校园建筑节能监管体系建设示范的13所院校之一',
    '2009年05月    ——     承接中新天津生态城新型能源项目的项目管理，标志着公司在新能源的项目管理、技术服务等领域走在国内前列',
    '2008年02月    ——     承接了华东地区规模最大的三级特等医院-合肥市中心医院的弱电智能化工程设计，证明了公司在业内的数字化医院建设开拓者和领跑者的地位',
    '2007年08月    ——     承接国家对外经援项目，签约安哥拉司法部大楼及安哥拉内图大学弱电总包合同',
    '2007年07月    ——     中标2010上海世博会——世博村弱电及智能化系统工程项目管理服务，公司迈入高端工程市场，并承接世博中国馆安防工程',
    '2006年12月    ——     公司年智能化设计、服务及施工合同额超2亿元',
    '2005年08月    ——     策应集团公司发展战略，公司总部迁入上海，更名为上海中电电子系统工程有限公司',
    '2003年05月    ——     实施浙江温州行政中心智能化系统总包项目，单体工程额达4800万元',
    '2002年10月    ——     公司股份制改造，成立江苏中电电子工程有限公司',
    '2000年08月    ——     签约泉州晚报大厦，与美国江森自控公司合作，率先将VAV空调系统引入中国，建成典型的工程案例',
    '1998年12月    ——     签约中国工商银行南京分行大楼，最早将千兆布线系统在国内应用，国内二十多家新闻媒体进行了报道',
    '1995年08月    ——     签约巴基斯坦恰希玛核电站项目，承包无线通信、寻呼、应急报警、广播等系统。自行研制的核电站专用声光报警系统获得应用',
    '1993年12月    ——     与香港电讯签约，代理美国AT﹠T公司结构化综合布线系统，成为最早将综合布线系统引入中国的公司之一',
    '1993年05月    ——     受上海728院委托，公司派出测试组，赴秦山核电站，对厂房内无线电波传输进行测试评估。 公司首次介入我国核电建设项目',
    '1989年10月    ——     首次签约弱电总包项目—江苏省展览馆'
  ],
  // 荣誉资质
  qualificationHonor1: [
    '建筑机电安装工程施工专业承包',
    '电子和智能化工程施工专业承包',
    '建筑智能化系统设计甲级证书',
    '公共安全防范工程设计施工',
    '专业灯光工程设计施工承包',
    '专业舞台机械工程设计施工承包',
    '专业音响工程设计施工承包',
    '专业舞台灯光音响工程设计施工一体化',
    '音视频智能系统集成工程资质',
    '音、视频工程业企业资质特级',
    '消防设施工程施工专业承包'
  ],
  qualificationHonor2: [
    '建筑装饰装修工程施工专业承包',
    '信息系统集成及服务资质',
    '建筑装饰工程设计专项',
    '涉密信息系统集成资质-系统集成',
    '城市及道路照明工程施工专业承',
    '上海市卫星地面接收设施安装许可证',
    '安全防范工程设计施工单位核准证书',
    '企业信用资质等级证书',
    '信用等级证书'
  ],
  // 团队力量
  teamStrength: '公司将老一代“电子人”专注与奉献的基因融入诚信、务实、精益、创新的企业文化。 重视团队的共享共担，关注每一位成员的成长，鼓励员工将生活的激情转化为工作中 的无限创意；通过公司多元化的活动，让员工能够发现活动中的乐趣，创造出无限新 意，铸造企业无限的活力。公司始终坚信“服务可以改变世界”，将科技成果转化为 企业力量，协同社会一同前行。'
}
