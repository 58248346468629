import axios from 'axios'
import _request from '@/utils/request'

const request = (opt) => _request({ isSDK: true, ...opt })

export default {
  /** v1.2 */
  // saas版本接口
  getSDKVersion: _ => request({ url: '/version', method: 'get' }),
  // 机构风格信息
  getOrganStyleInfo: _ => request({ url: '/style_info', method: 'get' }),
  /** 消息类型列表 */
  getMessageType: data => request({ url: '/message/message_types', method: 'POST', data }),
  /** 消息类型未读数 */
  getMessageTypeCount: data => request({ url: '/message/typeGroupCount', method: 'POST', data }),
  /** 部署配置 */
  getDeployConfig: _ => request({ url: '/deploy_config', method: 'get' }),

  /** */
  // 当前应用信息
  getAppInfo: _ => request({ url: '/app_info' }),
  // 获取请求授权url
  getAuthURL: _ => {
    // const url = location.href
    return request({
      method: 'get',
      url: `/auth`,
      params: { uri: location.origin }
    })
  },
  // code登录
  loginWithCode: code => request({ url: `/login`, method: 'post', data: { data: { accessType: 'pc', code, uri: location.origin }}}),

  // 机构列表
  getOrganList: _ => request({ url: `/list_organ` }),
  // 获取默认选择机构
  getCurOrgan: organId => request({ url: `/first_choose_organ/${organId}` }),
  // 选择机构
  chooseOrgan: organId => request({ url: `/choose_organ/${organId}` }),
  // 获取用户信息
  getUserInfo: _ => request({ url: '/current_user' }),
  // 获取当前用户资源
  getSource: _ => request({ url: '/current_source', method: 'post', data: { data: { extra1: 'pc', appType: 'menu' }}}),
  // 退出登录
  loginOut: _ => request({ url: '/logout' }),
  /** msg */
  // 消息通知
  getAllReadState: data => request({ url: '/message/all_state', data, method: 'post' }),
  getNotifyList: data => request({ url: '/message/page', data, method: 'post' }),
  delNotifyList: data => request({ url: '/message/batch', data, method: 'delete' }),
  setNotifyRead: data => request({ url: '/message/batch_read', data, method: 'post' }),
  // 消息接收管理
  getAllInfo: data => request({ url: '/message/manage/all_select_state', data, method: 'post' }),
  getMessageList: data => request({ url: '/message/manage/page', data, method: 'post' }),
  saveMessageRece: data => request({ url: '/message/manage', data, method: 'put' }),
  // 授权校验 TODO del showTips
  checkAuth: () => request({ url: '/auth/check_auth', showTips: false }),
  // 更新授权
  updateCode: (data) => request({ url: '/auth/update_code', data: { data }, method: 'post' }),

  // 获取Logo
  async  getLogo(url) {
    const service = axios.create({
      baseURL: '/',
      responseType: 'blob'
    })
    let ret = false
    service.interceptors.response.use(response => {
      const { status, data } = response
      if (status === 200 && data && data.type.includes('image')) ret = URL?.createObjectURL(data) ?? ''
    })
    await service(url)
    return ret
  }

}
