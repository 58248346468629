import NProgress from 'nprogress' // progress bar
import store from '@/store'
import router from './index'
import { startQiankun } from '@/micro'
import { getNotification } from 'saas-shared'
import { redirectRouter } from '@/utils'
import { UNITY_QUERY_KEY_ORGAN } from '@/CONST'

const microWhiteRoutes = '/w/' // 微应用白名单规则
let isFirst = true

// 路由加载前处理
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  // to and from are both route objects. must call `next`.

  const { menus } = store.state.user
  const { serviceInfo } = store.state.app

  /** 白名单微应用应用，从路由query获取应用信息 */
  const { _app } = to.query
  if (_app) {
    const whiteApp = JSON.parse(decodeURIComponent(Buffer.from(_app, 'base64')))
    console.log(whiteApp)
    store.commit('app/SET_APP_INFO', whiteApp)
  }
  /** 无需权限信息：登录、白名单 */
  if (to.path.startsWith('/login') || to.path.includes(microWhiteRoutes)) {
    if (to.path.startsWith('/login')) document.title = '登录'
    if (to.path === from.path && to.path === '/login') {
      next(false)
      NProgress.done()
      return
    }
    next()
    NProgress.done()
    return false
  }

  /** 授权过期 */
  if (serviceInfo?.isExpire && to.path === '/no-auth') {
    next()
    return
  }

  if (store.state.organ.currentOrganID || !isFirst) {
    /** 授权过期 */
    if (to.path === '/no-auth') {
      const { isExpire } = await store.dispatch('app/getServiceInfo')
      if (isExpire) {
        next()
        return
      }
    }
    redirectRouter({ to, next }, menus)
  } else {
    try {
      if (to.path === '/' && to.query.code) {
        const toURL = await store.dispatch('user/codeLogin', to.query.code)
        window.location.href = toURL
      } else {
        isFirst = false
        await store.dispatch('organ/getOrganList')

        // const OrganId = to.query[UNITY_QUERY_KEY_ORGAN] // 是否从统一门户跳转
        // OrganId && store.dispatch('organ/chooseOrgan', OrganId)

        store.dispatch('user/getUserInfo')
        store.dispatch('app/getDeployConfig')

        // 必要数据会阻塞页面加载
        await Promise.allSettled([
          store.dispatch('user/getMenus'),
          store.dispatch('app/getAppInfo'),
          store.dispatch('app/getServiceInfo'),
          store.dispatch('organ/getOrganStyleInfo'),
          store.dispatch('app/getSDKVersion')
        ])

        // 公共应用切换机构，请求携带机构id
        const { appInfo } = store.state.app
        if (appInfo.serviceType === '1') {
          const OrganId = to.query[UNITY_QUERY_KEY_ORGAN] // 是否从统一门户跳转
          OrganId && store.dispatch('organ/chooseOrgan', OrganId)
        }

        const { menus = [] } = store.state.user
        redirectRouter({ to, next }, menus, true)
      }
    } catch (error) {
      console.log('[ error ] >', error)
      if (error?.message?.code === 'A0230') {
        store.dispatch('user/toAuth')
      } else if (error === 'getOrganCurrent') {
      // 无机构调整未授权页面
        next('/no-permission')
      } else {
        next(false)
      }
    } finally {
      NProgress.done()
    }
  }
  NProgress.done()
})

// 路由加载后处理
router.afterEach(() => {
  // 加载qiankun微应用， afterEach 确保主应用主布局先加载
  const apps = store.state.app.microApps
  startQiankun(apps)

  // finish progress bar
  NProgress.done()
})

router.afterEach((to, from) => {
  // 企业服务授权管理
  const serviceInfo = store.state.app.serviceInfo
  if (serviceInfo.isShow) {
    getNotification(serviceInfo, { sureFn: () => store.commit('app/TRIGGER_AUTH_DIALOG', true) })
  }
})
