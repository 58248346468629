function getHasCodeValue(obj = {}, keyArr = [], defaultVal = null) {
  if (!(obj instanceof Object)) return defaultVal
  for (let index = 0; index < keyArr.length; index++) {
    const c = keyArr[index]
    if (obj[c]) return obj[c]
  }
  return defaultVal
}

/**
 * params 格式：1、{...} 2、{data:'json'}  3、{data:{....}}
 *
 * @param {*} params
 * @returns
 */
function parseJsonData(params = {}) {
  if (params.data) {
    try {
      return { ...params, ...JSON.parse(params.data) }
    } catch (err) {
      if (typeof params.data === 'object') {
        return { ...params, ...params.data }
      }
      console.warn(`[SaaS]: 菜单格式错误 ${JSON.stringify(params.data)}`)
      return params.data
    }
  }
  return params
}
/**
 * 格式化菜单extra
 * @param {*} sources
 * @param {*} parent
 * @returns
 */
export function resolveSources(sources, parent = {}) {
  if (!sources) return []
  const pathCode = ['path', 'url']
  const ret = []
  sources.forEach(sou => {
    const s = { ...sou }

    /** extra数据处理 */
    s.extra = parseJsonData(s.extra)
    s.code = s.code || s.id
    s.path = s.path || getHasCodeValue(s.extra, pathCode, `/${s.code}`) // 转化路由值 path | url | code

    /** 按钮权限 */
    if (s.extra.type === 'button') {
      if (parent) {
        parent.buttonPermission = parent.buttonPermission || []
        parent.buttonPermission.push(s.code)
      }
      return null
    }

    if (s.children) {
      s.children = resolveSources(s.children, s)
    }
    ret.push({ ...s, ...s.extra })
  })
  return ret
}
