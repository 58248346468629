import '../assets/fonts/font.css'
import './styles/index.scss'
import MessageNotify from './MessageNotify'
import MessageManage from './MessageManage'
import ErrorPage from './ErrorPage'
import Redirect from './Redirect'
import About from './About'
import UserProfile from './UserProfile'
import AuthDialog from './AuthDialog'
import NoAuth from './NoAuth'
import SaaSLayout from './layout'
import WeixinAuth from './Auth/weixinAuth.vue'
import StyleSet from './StyleSet'
import UserCard from './UserCard'

const components = [
  MessageNotify,
  MessageManage,
  ErrorPage,
  Redirect,
  About,
  UserProfile,
  AuthDialog,
  NoAuth,
  SaaSLayout,
  WeixinAuth,
  StyleSet,
  UserCard
]

export {
  MessageNotify,
  MessageManage,
  ErrorPage,
  Redirect,
  About,
  UserProfile,
  AuthDialog,
  NoAuth,
  SaaSLayout,
  WeixinAuth,
  StyleSet,
  UserCard
}

export default components
