<template>
  <div>
    <el-form-item label="微信绑定">
      <span v-if="hasBind">已绑定 <label class="ics-color-primary ics-pointer" @click="handleUnBind">解绑</label></span>
      <span v-else>未绑定 <label class="ics-color-primary ics-pointer" @click="getWechatAuthURL">绑定</label></span>
    </el-form-item>
    <!-- 弹窗 -->
    <ics-dialog
      :title="dialogTitle"
      :visible.sync="visible"
      :actions="hasBind"
      width="513px"
      :request="handleUnBindWeixin"
      @close="handleCloseDialog"
    >
      <div v-if="hasBind">
        <!-- 解绑微信 -->
        <el-form
          ref="unbindForm"
          label-width="80px"
          :model="unbindForm"
          :rules="ruleForm"
          @submit.native.prevent
        >
          <el-form-item label="手机号">
            <div class="code-item">
              <el-input v-model="userInfo.phone" disabled />
              <PhoneCode :phone="userInfo.phone" :api="$api.sendUnbindWeixinCode" />
            </div>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="unbindForm.code" />
          </el-form-item>
        </el-form>
      </div>
      <!-- 绑定微信 -->
      <div v-else id="login_container" class="weixin_box" />
    </ics-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PhoneCode from '../../PhoneCode'
export default {
  components: { PhoneCode },
  data() {
    return {
      visible: false,
      unbindForm: {
        code: ''
      },
      ruleForm: {
        code: [
          { required: true, message: '请输入验证码', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    dialogTitle() {
      return this.hasBind ? '解绑微信' : '绑定微信'
    },
    hasBind() {
      return this.userInfo.weixin
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    // 解绑确认
    handleUnBind() {
      // 解绑确认
      this.$confirm('解绑该微信，将不能使用该微信账号登陆平台， 需要重新进行绑定，是否解绑？', this.dialogTitle, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.visible = true
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解绑'
        })
      })
    },
    // 解绑
    handleUnBindWeixin() {
      return new Promise((resolve, reject) => {
        this.$refs.unbindForm.validate(valid => {
          if (valid) {
            this.$api.unbindWeixin({ data: { phone: this.userInfo.phone, ...this.unbindForm }}).then(_ => {
              this.$message.success('解绑成功')
              this.getUserInfo()
              resolve()
            }).catch(err => {
              reject(err)
            })
          } else {
            reject()
          }
        })
      })
    },
    //
    handleCloseDialog() {
      this.$refs?.ruleForm?.resetFields()
      this.visible = false
    },
    getWechatAuthURL() {
      this.$api.getWeixinAuthUri({ data: { accessType: 'pc', redirectUri: location.origin + '/auth/wechat/callback', state: '/userProfile' }}).then(res => {
        console.log(res)
        location.href = res.data
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.code-item{
  display: flex;
}
.weixin_box{
  width: 300px;
  margin: 0 auto;
}
</style>
