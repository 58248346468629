import Vue from 'vue'
import NProgress from 'nprogress'
import { addGlobalUncaughtErrorHandler, start, registerMicroApps } from 'qiankun'

import store from '@/store'
import pkg from '@/../../package.json'

// import resolveProps from './resolveProps'
import { resolveProps, getFirstDeepChild } from 'saas-shared'
import {
  directive_has,
  has,
  getRequestGateway,
  request,
  sessionStorageChange
} from '@/utils'
import { OpenDevStorage } from '@/setting'

// 是否已开启
let isStart

/** 自定义 微应用空白路由处理 */
function microResolveProps(props, { router: { router, asyncRoutes }, store: microState }) {
  router.beforeEach((to, from, next) => {
    const first = getFirstDeepChild(store.state.user.menus)
    if (!to.name) {
      return next(first)
    }
    next()
  })
  resolveProps(props, { router: { router, asyncRoutes }, store: microState })
}

const props = {
  action: {
    getRequestGateway,
    request,
    resolveProps: microResolveProps,
    directive_has,
    has,
    chooseOrgan: (id) => store.dispatch('organ/chooseOrgan', id),
    logout: () => store.dispatch('user/logout'),
    toAuth: () => store.dispatch('user/toAuth')
  },
  root: {
    version: pkg.version,
    name: pkg.name
  }
}

export const startQiankun = async(apps = []) => {
  if (isStart) return
  isStart = true

  const appData = sessionStorageChange('app', apps, OpenDevStorage && apps.length)

  const resolveApp = appData.map(app => {
    app.loader = loader => store.commit('app/SET_LOADING', loader)
    app.props = props
    return app
  })

  Vue.prototype.$apps = resolveApp

  registerMicroApps(resolveApp, {
    beforeMount: app => {
      NProgress.start()
    },
    afterMount: _ => {
      NProgress.done()
    }
  })

  // 全局异常处理
  addGlobalUncaughtErrorHandler(event => {
    if (event?.message) console.error(`${event.message}`)
    if (store.state.app.loading) store.commit('app/SET_LOADING', false)
  })

  // 启动服务
  start({
  // prefetch: 'all',
    fetch: window.fetch
  })
}

