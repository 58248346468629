<!--  -->
<template>
  <div class="user-profile ics-flex_center">
    <div v-loading="loading" class="user-profile-wrap">
      <div class="user-profile-title">账号设置</div>
      <div class="user-profile-content">
        <section class="user-profile-content--img">
          <div class="img-wrap ics-pointer">
            <el-image
              class="img"
              :src="'/api' + userInfo.headimg"
              :fit="'cover'"
            >
              <img slot="error" class="img-error" src="~shared/assets/avatar.png" alt="">
            </el-image>
            <div class="tips" @click="editHeadImg">修改</div>
          </div>
        </section>
        <section class="user-profile-content--item">
          <div class="title">基础信息</div>
          <el-form size="mini" label-width="80px" label-suffix=":">
            <el-form-item label="用户名">
              <span>{{ userInfo.username }}</span>
              <i class="el-icon-edit-outline ics-color-primary ics-pointer ml20" @click="editUsername" />
            </el-form-item>
            <el-form-item label="手机号">{{ userInfo.phone }}</el-form-item>
            <el-form-item label="注册时间">{{ userInfo.createTime }}</el-form-item>
            <BindWeixin />
          </el-form>
        </section>
        <section class="user-profile-content--item">
          <div class="title">详细信息</div>
          <el-form
            ref="ruleForm"
            label-width="80px"
            label-suffix=":"
            :model="ruleForm"
            :rules="rules"
          >
            <el-form-item label="邮箱地址" prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="请输入邮箱地址"
                maxlength="32"
                style="width: 340px"
              />
            </el-form-item>
            <el-form-item label="webhook" prop="webhook">
              <el-input
                v-model="ruleForm.webhook"
                placeholder="请输入webhook"
                maxlength="256"
                style="width: 340px"
              />
              <el-popover
                placement="top-start"
                trigger="hover"
                :content="'可以配置钉钉和微信公众号推送，需提前关注公众号，并配置相应推送地址'"
              >
                <i slot="reference" class="el-icon-question ics-color-primary ml20" />
              </el-popover>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save">保存</el-button>
            </el-form-item>
          </el-form>
        </section>
      </div>
      <upload ref="upload" @updateInfo="getUserInfo" />
      <ics-dialog title="编辑" :visible.sync="isUsernameEdit" width="500px" :loading="userFormLoading">
        <el-form v-model="userInfoForm" inline>
          <el-form-item label="用户名">
            <el-input v-model.trim="userInfoForm.username" maxlength="40" />
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="isUsernameEdit = false">取消</el-button>
          <el-button :loading="loading" type="primary" @click="checkUsername">保存</el-button>
        </span>
      </ics-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import upload from './components/upload.vue'
import BindWeixin from './components/bindWeixin.vue'

export default {
  name: 'UserProfile',
  components: { upload, BindWeixin },
  data() {
    return {
      isUsernameEdit: false,
      userFormLoading: false,
      userInfoForm: {},
      ruleForm: {
        email: '',
        webhook: ''
      },
      loading: false,
      rules: {
        email: [
          {
            pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
            trigger: ['blur', 'change'],
            message: '请输入正确的邮箱地址'
          }
        ],
        webhook: [
          {
            pattern: /^http(s?):\/\/([\w.]+\/?)\S*/,
            trigger: ['blur', 'change'],
            message: '应以http或https作为开头'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('user', ['userInfo'])
  },
  async mounted() {
    this.init()
  },

  methods: {
    ...mapActions('user', ['getUserInfo']),
    init() {
      this.getUserData()
    },
    editUsername() {
      this.userInfoForm = { ...this.userInfo }
      this.isUsernameEdit = true
    },
    checkUsername() {
      const { username } = this.userInfoForm
      if (!username) {
        this.$message.info('用户名不能为空')
        return
      }
      this.userFormLoading = true
      this.$api.updateAccount({ data: { username }}).then(res => {
        this.$message.success('修改成功')
        this.getUserInfo()
        this.isUsernameEdit = false
      }).finally(_ => {
        this.userFormLoading = false
      })
    },
    editHeadImg() {
      this.$refs.upload.show()
    },
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api.saveUserInfo({ data: this.ruleForm }).then((res) => {
            this.$message.success('保存成功')
          }).finally(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    getUserData() {
      this.loading = true
      this.$api.getInfo({}).then((res) => {
        this.ruleForm.email = res.data.email
        this.ruleForm.webhook = res.data.webhook
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang='scss' scoped>
$img-size : 100px;
.ml20{
  margin-left:20px
}

.user-profile{
  background: $--background-color-base;
  height: 100vh;
  overflow: scroll;

  &-wrap{
    box-sizing: border-box;
    width: 900px;
    height: 804px;

    background: $--color-white;
    border-radius: 10px;
  }
  &-title{
    padding: 20px 0;
    text-align: center;

    font-size: $--font-size-extra-large;
    border-bottom: $--border-base;
  }
  &-content{
    padding: 20px 50px;
    &--img{
      padding: 20px 0;
      margin-bottom:20px;
      .img-wrap{
        width: $img-size;
        height: $img-size;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto;
        .img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: $--border-base;
        }
        .tips{
          display: none;
        }
        &:hover{
          .tips{
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $img-size;
            height: $img-size;
            background: rgba($color: #000000, $alpha: 0.5);
            border-radius: 50%;
            color: #fff;
            font-size: $--font-size-base;
          }
        }
      }
    }
    &--item{
      padding: 30px 35px;
      .title{
        font-size: $--font-size-medium;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
     &--item +  &--item{
      border-top: $--border-base;
     }
  }
}
</style>
