// import moment from 'moment'
import api from '@/api'
import { closeNotification, setAPPGlobalConfig } from 'saas-shared'
import { resolveApps } from '@/micro/utils'
import { GLOBAL_APP_KEYS } from '@/CONST'

const state = {
  loading: false,
  appInfo: {},
  microApps: [],
  logoSrc: '',
  msgReadStatus: false,
  serviceInfo: {},
  isShowAuthDialog: false,
  sdkVersion: '',
  deployConfig: {}

}
const mutations = {
  SET_DEPLOY_CONFIG(state, data) {
    state.deployConfig = data
  },
  SET_SDK_VERSION: (state, version) => {
    state.sdkVersion = version
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  SET_APP_INFO: (state, appInfo) => {
    state.appInfo = appInfo
    state.microApps = resolveApps(appInfo)
    // 添加应用名称
    document.title = appInfo.name
  },
  SET_MSG_STATUS(state, status) {
    state.msgReadStatus = status
  },
  SET_LOGO_SRC(state, src) {
    state.logoSrc = src
  },
  SET_SERVICE_INFO(state, data) {
    state.serviceInfo = data
  },
  TRIGGER_AUTH_DIALOG(state, date) {
    state.isShowAuthDialog = date
  }
}

const actions = {
  /** 获取部署配置 */
  getDeployConfig({ commit }) {
    api.getDeployConfig().then(res => {
      const { data } = res || {}
      const defaultWindowConfig = {
        unity: 'http://saas-unity-front.saas-dev.192.168.4.124.nip.io:32140' // 统一门户平台地址
      }
      const domain = data?.domain || defaultWindowConfig
      domain.unityHomePage = domain.unity
      domain.microUrl = '/subapp/'
      window.__SAAS_APP_CONFIG__ = { ...domain, ...window?.__SAAS_APP_CONFIG__ }
      commit('SET_DEPLOY_CONFIG', data)
    })
  },
  /** 获取sdk版本号 */
  getSDKVersion() {
    return new Promise((resolve, reject) => {
      api.getSDKVersion().then(res => {
        const { libs = [] } = res.data || {}
        const sdkInfo = libs.find(l => l.name === 'cec-saas-app-sdk-inside')
        const shortVersion = sdkInfo.version.slice(0, 3) // 取前两位版本号
        setAPPGlobalConfig(GLOBAL_APP_KEYS.sdkVersion, shortVersion)
      }).finally(_ => {
        resolve()
      })
    })
  },
  getAppInfo({ commit }) {
    return new Promise((resolve, reject) => {
      api.getAppInfo().then(res => {
        const { data } = res
        commit('SET_APP_INFO', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  async getAppLogo({ commit }) {
    const logoSrc = await api.getLogo('/logo.jpg')
    commit('SET_LOGO_SRC', logoSrc)
  },
  getAllMessageState({ commit, state }) {
    return new Promise((resolve, reject) => {
      api.getAllReadState({}).then(({ data }) => {
        const isRead = !data || false
        if (state.msgReadStatus !== isRead) commit('SET_MSG_STATUS', isRead)
        resolve(isRead)
      })
    })
  },
  getServiceInfo({ commit }) {
    return new Promise((resolve, reject) => {
      api.checkAuth().then(res => {
        const info = { ...res.data }

        const { stop, tips, trial } = info
        // const isShow = moment(expireDate).isBetween(new Date(), moment().add(15, 'd'))
        // const isExpire = !moment(expireDate).isAfter()

        const resData = {
          ...info,
          isShow: tips && !stop, // 是否提醒
          isExpire: stop, // 是否终止服务
          trial: trial// 是否试用
        }
        // 关闭提示框
        if (!tips) { closeNotification() }
        commit('SET_SERVICE_INFO', resData)
        resolve(resData)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
