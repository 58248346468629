var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.curOrganName)?_c('div',{staticClass:"organ-popper saas-pointer"},[_c('el-popover',_vm._b({attrs:{"placement":"bottom-end","width":308,"offset":10,"popper-class":"saas-popper-wrap layout-organ-popper"},model:{value:(_vm.organVisible),callback:function ($$v) {_vm.organVisible=$$v},expression:"organVisible"}},'el-popover',_vm.popoverConfig,false),[_c('span',{staticClass:"organ-popper--reference saas-flex-space_between",attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"saas-ellipsis"},[_vm._v(_vm._s(_vm.curOrganName))]),_c('i',{staticClass:"el-icon-caret-bottom icon-right"})]),_c('div',{class:[
        'organ-popper-item',
        'saas-flex-space_between',
        'saas-pointer',
        'saas-hover-bg',
        'active'
      ]},[_c('span',{staticClass:"organ-popper-item--span"},[_vm._v(_vm._s(_vm.curOrganName))]),_c('i',{staticClass:"el-icon-check"})]),_vm._l((_vm.sortOrganList),function(item){return _c('div',{key:item.id,class:[
        'organ-popper-item',
        'saas-flex-space_between',
        'saas-pointer',
        'saas-hover-bg',
      ],on:{"click":function($event){return _vm.chooseOrganItem(item.id)}}},[_c('span',{staticClass:"organ-popper-item--span"},[_vm._v(_vm._s(item.name))])])})],2),_c('div',{staticClass:"organ-popper-button"},[(_vm.isShowToBack)?_c('span',{on:{"click":_vm.toOrgan}},[_vm._v("切换后台")]):_c('span',{on:{"click":_vm.toUnity}},[_vm._v("切换门户")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }