import { Notification } from 'element-ui'
let _isServiceShow
let notifyProp

export function getNotification(data, { sureFn }) {
  /** 兼容 作为iframe 嵌入微应用 */
  const isIframe = self !== top

  if (!data.isShow) {
    _isServiceShow = false
    return
  }
  if (_isServiceShow || isIframe) return
  _isServiceShow = true

  const { between, expireDate, admin } = data
  const buttonStyle = 'margin-top:10px;text-align:right'
  const labelStyle = 'color:#EE4B38'
  const buttonWrap = `<div style='${buttonStyle}'>
    <button class="el-button el-button--mini" onclick="closeNotification()">关闭提醒</button>
    <button class="el-button el-button--primary el-button--mini" onclick="showService()">延长期限</button>
  </div>`

  // 关闭提醒
  const stopMSG = `您的企业服务已到期,将于<label style='${labelStyle}'>${expireDate}</label>关闭服务，为了保障系统正常使用，`
  // 到期提醒
  const expireMSG = `您的企业服务于${expireDate}到期,剩余<label style='${labelStyle}'>${between}</label>，`

  let message = data.trial ? stopMSG : expireMSG
  message = '</span>' + message + (admin ? '请及时联系我们延长使用期限。' : '请及时联系管理员延长使用期限。') + '</span>'

  if (admin) message = '<div>' + message + buttonWrap + '</div>'

  window.closeNotification = function() { Notification.closeAll() }
  window.showService = sureFn

  notifyProp = Notification.warning({
    title: `企业服务即将${data.trial ? '关闭' : '到期'}`,
    duration: 0,
    offset: 70,
    dangerouslyUseHTMLString: true,
    message,
    customClass: 'saas-auth-notification'
  })
}

export function closeNotification() {
  if (notifyProp) notifyProp.close()
}
