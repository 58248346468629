<!--  -->
<template>
  <div class="page-wrap">
    <ics-table
      v-show="!showDetail"
      ref="icsTable"
      v-bind="tableConfig"
      :request="getData"
      :params="searchForm"
      @selection-change="handleSelectionChange"
    >
      <template #search>
        <span v-if="isShowBack" class="saasfont icon-back" @click="goHome" />
        <ics-search-form :value="searchForm" :columns="searchList" @search="refreshTable" @reset="resetData" />
      </template>
      <template #toolbarAction>
        <el-button type="primary" size="mini" @click="acceptVisible = true">接收设置</el-button>
        <el-button type="primary" size="mini" :disabled="!selection.length" @click="setRead()">标记已读</el-button>
        <el-button size="mini" :disabled="!selection.length" @click="deleteMessage()">批量删除</el-button>
      </template>
    </ics-table>
    <MessageNotifyDetail v-show="showDetail" class="detail" :detail-data="detailData" @showInfo="showDetail = false" />
    <ics-dialog :visible.sync="acceptVisible" title="接收设置">
      <AcceptSetting />
    </ics-dialog>
  </div>
</template>

<script lang='jsx'>
import { mapActions } from 'vuex'
import MessageNotifyDetail from './MessageNotifyDetail.vue'
import AcceptSetting from './AcceptSetting.vue'
import { getCurPlat } from 'shared/sdk'

export default {
  name: 'MessageNotify',
  components: { MessageNotifyDetail, AcceptSetting },
  data() {
    return {
      acceptVisible: false,
      loading: false,
      showDetail: false,
      detailData: {},
      searchForm: {
        state: '2'
      },
      searchList: [
        { label: '消息标题', prop: 'title', maxlength: '32', valueType: 'text' },
        { label: '状态', prop: 'state', valueType: 'select', options: [
          { value: '2', label: '全部' },
          { value: '1', label: '已读' },
          { value: '0', label: '未读' }
        ]
        }
      ],
      selection: [],
      tableConfig: {
        selection: true,
        showOverflowTooltip: true,
        columns: [
          { type: 'selection', align: 'center' },
          { label: '状态', prop: 'state', render: (h, { row }) => {
            return (<el-tag type={!row.state ? 'danger' : 'success'} size='small' effect='dark'> {!row.state ? '未读' : '已读'} </el-tag>)
          } },
          { label: '消息标题', prop: 'title' },
          { label: '所属机构', prop: 'sourceTenantName' },
          { label: '来源', prop: 'pushSourceName' },
          { label: '时间', prop: 'createTime' },
          { label: '消息类型', prop: 'messageTypeName' },
          { label: '操作', valueType: 'action',
            actions: [
              { label: '详情', type: 'text', size: '14', click: row => this.openDetail(row) }
            ]
          }
        ]
      }
    }
  },

  computed: {
    isShowBack() {
      return getCurPlat() === 'unity'
    }
  },

  mounted() {},

  methods: {
    ...mapActions('app', ['getAllMessageState']),
    goHome() {
      this.$router.push('/')
    },
    // 搜索-查询
    refreshTable() {
      this.$refs.icsTable.reload('search')
    },
    // 搜索 - 重置
    resetData() {
      this.searchForm = this.$options.data().searchForm
      this.refreshTable()
    },
    // 表格-复选
    handleSelectionChange(arr) {
      this.selection = arr.map(item => item.id)
    },
    // 获取表格数据
    async getData(params) {
      const { state } = this.searchForm
      params.data.state = state === '2' ? null : state === '1'// 已读true false null
      const res = await this.$api.getNotifyList(params)
      const { records, total } = res.data
      return { success: res.success, data: records, total }
    },
    // 批量删除
    deleteMessage() {
      this.$confirm('是否删除选中消息?', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$api.delNotifyList({ data: { ids: this.selection }}).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.sendMessToMainApp()
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 标记已读
    setRead() {
      this.loading = true
      this.$api.setNotifyRead({ data: { ids: this.selection }}).then(res => {
        this.$message({
          type: 'success',
          message: '设置已读成功'
        })
        this.sendMessToMainApp()
      }).finally(() => {
        this.loading = false
      })
    },
    // 更新消息通知状态
    sendMessToMainApp() {
      this.getAllMessageState()
      this.refreshTable()
    },
    // 详情展示
    async openDetail(row) {
      await this.$api.setNotifyRead({ data: { ids: [row.id] }})
      this.sendMessToMainApp()
      if (row.jumpUrl) {
        window.open(row.jumpUrl, '_blank')
      } else {
        this.detailData = row
        this.showDetail = true
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.icon-back{
  width: 28px;
  padding: 0 4px;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
  &:hover{
    color: $--color-primary;
  }
}
</style>
