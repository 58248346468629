// import moment from 'moment'
import { resolveApps, closeNotification } from 'shared/sdk'

export default api => {
  const state = {
    loading: false,
    microApps: [],
    msgReadStatus: false,
    logoSrc: '',
    serviceInfo: {},
    isShowAuthDialog: false,
    deployConfig: {}
  }

  const mutations = {
  // ========= loading
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_APP_INFO(state, microApps) {
      state.microApps = microApps
    },
    SET_MSG_STATUS(state, status) {
      state.msgReadStatus = status
    },
    SET_LOGO_SRC(state, src) {
      state.logoSrc = src
    },
    SET_SERVICE_INFO(state, data) {
      state.serviceInfo = data
    },
    TRIGGER_AUTH_DIALOG(state, date) {
      state.isShowAuthDialog = date
    },
    SET_DEPLOY_CONFIG(state, data) {
      state.deployConfig = data
    }
  }

  const actions = {
    async getApps({ commit }) {
      return new Promise((resolve, reject) => {
        api.getMicroApps().then(res => {
          const { data } = res
          const ret = resolveApps(data)
          commit('SET_APP_INFO', ret)
          resolve(ret)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async getAppLogo({ commit }) {
      const logoSrc = await api.getLogo('/logo.jpg')
      commit('SET_LOGO_SRC', logoSrc)
    },
    getAllMessageState({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.getAllReadState({}).then(({ data }) => {
          const isRead = !data || false
          if (state.msgReadStatus !== isRead) commit('SET_MSG_STATUS', isRead)
          resolve(isRead)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getServiceInfo({ commit }) {
      return new Promise((resolve, reject) => {
        api.checkAuth().then(res => {
          const info = { ...res.data }

          const { stop, tips, trial } = info
          // const isShow = moment(expireDate).isBetween(new Date(), moment().add(15, 'd'))
          // const isExpire = !moment(expireDate).isAfter()

          const resData = {
            ...info,
            isShow: tips && !stop, // 是否提醒
            isExpire: stop, // 是否终止服务
            trial: trial// 是否试用
          }
          // 关闭提示框
          if (!tips) { closeNotification() }
          commit('SET_SERVICE_INFO', resData)
          resolve(resData)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }

  return {
    namespaced: true,
    state,
    mutations,
    actions
  }
}
