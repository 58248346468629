<template>
  <div class="error-page">
    <ics-status-page :code="code" />
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  computed: {
    code() {
      return this.$route.path.replace('/', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page{
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
