import Request, { Gateway } from 'ics-request'
import { Message } from 'element-ui'
import store from '@/store'
import { sleep } from '@/utils'
import router from '@/router'

export const ConfigBaseURL = '/api/'

// 错误码配置
const errorEvent = {
  'A0230': () => store.dispatch('user/toAuth'), // 登录失效
  'A0507': async(data) => {
    // 页面数据过期
    data?.message?.message && Message.error(data.message.message)
    await sleep(1000)
    location.reload()
  },
  'A4000': async() => {
    Message.info('机构已切换，正在切换中...')
    await sleep(1000)
    // 跳转第一个菜单
    location.href = '/'
  },
  'A0301': () => store.dispatch('user/toAuth'),
  'A0311': () => router.replace('/no-auth')
}

const networkErrorEvent = (error, showTips, normalErrorResolve) => {
  const { status, config } = error.response
  if (status === 404 && config.isSDK) {
    /** sdk 接口兼容处理 */
    console.error(`${config.url}请求未找到，请升级SDK`)
  } else {
    normalErrorResolve(error)
  }
}

const request = Request({
  baseURL: ConfigBaseURL,
  withCredentials: true,
  timeout: 1000 * 60,
  callbackAll: true // 返回所有数据（包含接口状态、数据、信息） ，为false时只返回data对象
}, errorEvent, networkErrorEvent)

// 请求拦截
request.interceptors.request.use(config => {
  config.headers['organ-id'] = store.state.organ?.currentOrganID || ''
  return config
})

// 响应拦截
// request.interceptors.response.use()

export function getRequestGateway({ name, gateway }, proxyURL = ConfigBaseURL) {
  // !注意： 网关会缓存在localStorage中，修改网关时需要先清除缓存
  const requestGateway = new Gateway({ name, gateway }, process.env.VUE_APP_ENV === 'staging')

  return (key = 'default') => {
    if (requestGateway.get(key) !== undefined) {
      const gatewayVal = requestGateway.get(key)
      return proxyURL + gatewayVal
    } else {
      throw Error(`key ${key} is not defined in Gateway`)
    }
  }
}

export default request
export { request }
