import { install } from './install'
import './assets/iconfont/iconfont.css'

export * from './sdk'
export * from './components'
export * from './assets/CONST'

export default {
  version: '__VERSION__',
  install
}
