import { toLogin } from 'shared/sdk'
export const popoverConfig = {
  closeDelay: 50,
  trigger: 'hover'
}

export const searchForm = {
  pageNum: 1,
  pageSize: 5,
  total: 0
}

/** 头像下拉列表菜单 */
export const dropdownMenus = ({ plan = 'A', showAbout } = {}) => ([
  {
    id: '/userProfile',
    name: '账号设置'
  }, {
    id: '/styleSet',
    name: '风格设置',
    permission: 'StyleSet', // 是否有权限控制
    isRoute: true
  }, {
    id: '/about',
    name: '关于中电',
    isShow: () => showAbout // 部署配置是否展示关于重点，默认不展示
  }, {
    id: '/login/reset-pwd-login',
    name: '修改密码',
    fn: (path) => toLogin(path, true)
  }, {
    id: 'loginOut',
    name: '退出登录',
    store: 'user/logout'
  }
])
