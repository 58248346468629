import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes, statusRoutes } from './constantRoutes'

// export { whiteSidebarRoutes } from './constantRoutes'
export {
  routes,
  statusRoutes
}

Vue.use(VueRouter)

const newRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = newRouter()

router.$addRoutes = (params) => {
  router.matcher = newRouter().matcher

  params.forEach(item => {
    router.addRoute(item)
  })
}

export default router

