// import Layout from '@/layout'
import Layout from '@/views/Layout'
import Version from '@/views/version'

// import MessageNotify from '@/views/message/messageNotify'
/** 使用 window['saas-components'] 解决静态资源路径问题 */
import {
  NoAuth,
  Redirect,
  ErrorPage,
  MessageNotify
} from 'saas-shared'

// 页面跳转失败时相应的状态页
export const statusRoutes = [
  {
    path: '/no-permission',
    name: 'NoPermission',
    component: ErrorPage,
    meta: { isOnlyContent: true, title: '无权限' }
  },
  {
    path: '/no-auth',
    name: 'NoAuth',
    component: NoAuth,
    meta: { isOnlyContent: true }
  }
]

export const routes = [
  {
    path: '/redirect',
    name: 'Redirect',
    component: Layout,
    // hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'RedirectPage',
        component: Redirect
      }
    ]
  },
  {
    path: '/version',
    name: 'Version',
    component: Version,
    meta: { fullScreen: true }
  },
  {
    path: '*',
    // name: 'Index',
    component: Layout,
    children: [
      ...statusRoutes,
      {
        code: 'Message',
        name: 'Message',
        path: '/message/messageNotify',
        component: MessageNotify,
        meta: { title: '消息通知' }
      }
    ]
  }
]
