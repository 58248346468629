<template>
  <div class="page-wrap">
    <AcceptSetting />
  </div>
</template>

<script>
import AcceptSetting from '../MessageNotify/AcceptSetting.vue'

export default {
  name: 'MessageManage',
  components: { AcceptSetting }
}
</script>
