export const _cacheKey = '__is_use_storage__'

// 本地数据存储/获取
export const localStorageChange = storageChange(localStorage)

// 本地数据存储/获取
export const sessionStorageChange = storageChange(sessionStorage)

/** 获取config */
export function getDevConfig() {
  try {
    return JSON.parse(sessionStorage.getItem(_cacheKey))
  } catch (error) {
    return false
  }
}

/** 设置config */
export function setDevConfig(d) {
  sessionStorage.setItem(_cacheKey, d)
}

/** 是否存在 */
export function hasDevConfig() {
  return sessionStorage.getItem(_cacheKey)
}

function storageChange(storage) {
  return (name, value) => {
    const isCache = getDevConfig()
    if (name && isCache) {
      name = '__dev__' + name
      const val = storage.getItem(name)
      if (!val) {
        storage.setItem(name, JSON.stringify(value))
      } else {
        value = JSON.parse(val)
      }
    }

    return value
  }
}
