/**
 * 获取第一个菜单对象
 * @param {*} menus
 * @returns
 */
export function getFirstDeepChild(menus) {
  const { length } = menus
  if (!length) return null

  const firstEl = menus.find(m => !m.hidden) // 过滤hidden菜单
  if (!firstEl?.children?.length) return firstEl
  return getFirstDeepChild(firstEl.children)
}

/**
 * 跳转配置
 * @param {*} to
 * @param {*} menus 菜单
 * @param {*} next
 * @param {*} replace 是否需要重新进入 (路由动态加载时)
 */
export function redirectRouter(to, next, menus, { isWhitePath, isStatusPath, replace }) {
  if (menus?.length) {
    const firstUrl = getFirstDeepChild(menus)?.path || ''

    if (isStatusPath || to.path === '/') {
      next({ path: firstUrl })
    } else {
      if (replace) {
        next({ ...to, replace: true })
      } else {
        next()
      }
    }
  } else {
    // 无权限
    if (isWhitePath) {
      next()
    } else {
      next('/no-permission')
    }
  }
}
