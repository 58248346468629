import cloneDeep from 'lodash/cloneDeep'
import api from '@/api'
import { STYLE_NORMAL } from 'shared'
import { ORGAN_ID_KEY } from '@/CONST'

const CONST_KEY = {
  organDefaultKey: 'choose' // 机构列表默认机构标识
}

const state = {
  organList: [],
  currentOrganID: '',
  organUserInfo: {},
  curOrganInfo: {},
  organStyleInfo: STYLE_NORMAL()
}

const mutations = {
  // ========= 机构
  SET_ORGAN_LIST: (state, list) => {
    state.organList = list
    // MicroApp v1
  },
  SET_DEFAULT_ORGAN: (state, id) => {
    state.currentOrganID = id
    // v1.2
    state.curOrganInfo = state.organList.find(o => o[ORGAN_ID_KEY] === id)
  },
  // 用户
  SET_USER_INFO: (state, data) => {
    state.organUserInfo = data
    // MicroApp v1
  },
  SET_ORGAN_STYLE_INFO(state, data) {
    if (!data.companyName) { data.companyName = state.curOrganInfo.name }
    if (!data.bannerColor) { data.bannerColor = STYLE_NORMAL().bannerColor }
    if (!data.textColor) { data.textColor = STYLE_NORMAL().textColor }
    state.organStyleInfo = { ...data }
  }
}

const getters = {
  curOrganName(state) {
    return state.organStyleInfo?.companyAbbreviation || state.organStyleInfo?.companyName || state.curOrganInfo.name
  }
}

const actions = {
  getOrganList({ commit, dispatch }) {
    // 获取机构列表
    return new Promise((resolve, reject) => {
      api.getOrganList().then(async res => {
        const { data } = cloneDeep(res)
        commit('SET_ORGAN_LIST', data)

        if (!data?.length) {
          // 无机构
          reject('getOrganCurrent')
        } else {
          const list = data?.map(item => {
            const temp = { ...item, id: item?.[ORGAN_ID_KEY] }
            // 设置默认机构
            if (item[CONST_KEY.organDefaultKey]) {
              commit('SET_DEFAULT_ORGAN', temp.id)
              commit('user/SET_DEFAULT_ORGAN', temp.id, { root: true })
            }
            return temp
          }) || []
          commit('SET_ORGAN_LIST', list)
          commit('user/SET_ORGAN_LIST', list, { root: true })
          // 兼容处理v1.2之前, 获取当前默认机构
          if (!state.currentOrganID) {
            const firstID = list[0][ORGAN_ID_KEY]
            await dispatch('getOrganCurrent', firstID)
          }
          resolve(list)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 切换机构
  async getOrganCurrent({ state, dispatch, commit }, id) {
    try {
      const { data } = await api.getCurOrgan(id)
      commit('SET_DEFAULT_ORGAN', data)
      commit('user/SET_DEFAULT_ORGAN', data, { root: true })
      return true
    } catch (error) {
      return Promise.reject('getOrganCurrent')
    }
  },
  getOrganUserInfo({ state, commit }) {
    return new Promise((resolve, reject) => {
      api.getUserInfo().then(res => {
        const { data } = cloneDeep(res)
        commit('SET_USER_INFO', data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 切换机构
  chooseOrgan({ state }, id) {
    api.chooseOrgan(id).then(res => {
      location.href = '/'
    })
  },
  /** 获取机构风格设置 */
  async getOrganStyleInfo({ commit }) {
    try {
      const { data } = await api.getOrganStyleInfo()
      commit('SET_ORGAN_STYLE_INFO', data)
      return data
    } catch (error) {
      commit('SET_ORGAN_STYLE_INFO', STYLE_NORMAL())
      return STYLE_NORMAL()
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
