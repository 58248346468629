// let _current

const _platarr = {
  boss: 'boss平台',
  organ: '机构平台',
  open: '开放平台',
  business: '业务中台',
  unity: '统一门户'
}

/**
 * 通过地址获取当前平台
 * @returns
 */
function getLocationPlat() {
  const { hostname } = location
  const keys = Object.keys(_platarr)
  const current = keys.find(key => hostname.includes(key))
  return current
}

/**
 *
 * @returns
 */
export function getCurPlat() {
  const _current = window?.global_config?.CUR_PLAT || getLocationPlat() || 'organ'
  if (!window.global_config) window.global_config = {}
  window.global_config.CUR_PLAT = _current

  return _current
}

/**
 *
 * @returns
 */
export function getAppType(plat) {
  const CUR_PLAT = plat || getCurPlat()
  const appType = CUR_PLAT === 'boss' ? 'boss' : 'organ'

  return appType
}

/**
 *
 * @returns
 */
export function getPlatTitle(plat) {
  const CUR_PLAT = plat || getCurPlat()

  return (_platarr[CUR_PLAT] || 'saas平台')
}

export function getPlatInfo() {
  const current = getCurPlat()
  return {
    curType: current,
    appType: getAppType(current),
    title: getPlatTitle(current)
  }
}
