const APP_WINDOW_KEY = '__SAAS_APP_CONFIG__'

export function setAPPGlobalConfig(key = 'version', data) {
  window[APP_WINDOW_KEY][key] = data
}

export function getAPPGlobalConfig(key) {
  if (key === undefined) return window[APP_WINDOW_KEY]
  return window[APP_WINDOW_KEY]?.[key] || null
}
