<template>
  <ics-layout
    ref="layout"
    v-bind="Options"
  >
    <!-- header banner -->
    <div slot="header">
      <SaaSHeader v-bind="Options.header" />
    </div>

    <!-- 内容 -->
    <slot>
      <div v-loading="loading" class="app-layout-mian">
        <router-view v-if="!!$route.name" />
        <div id="frame" />
      </div>
    </slot>
  </ics-layout>
</template>

<script>
import { mapState } from 'vuex'

import SaaSHeader from './header'

export default {
  name: 'SaaSLayout',
  components: { SaaSHeader },
  data() {
    return { }
  },

  computed: {
    ...mapState('user', ['menus']),
    ...mapState('app', ['loading', 'logoSrc']),
    Options() {
      const isOnlyContent = !this.$route?.meta?.isOnlyContent
      const defaultOpts = {
        menus: this.menus,
        splitMenus: false,
        header: {
          logoSrc: this.logoSrc
        },
        contentStyle: { 'padding': '20px' },
        'show-sidebar': isOnlyContent,
        'show-tag-view': isOnlyContent
      }
      return { ...defaultOpts, ...this.$attrs }
    }
  },
  watch: {
    '$route': {
      handler(nv, ov) {
        if (!nv.name) {
          this.$nextTick(_ => {
            const { addTagView, activeMenus } = this.getEl()
            const { code, name } = activeMenus.at(-1)
            if (name) {
              const routes = {
                ...nv,
                name: code,
                meta: { title: name }
              }
              addTagView(routes)
            }
          })
        }
      },
      immediate: true
    }
  },

  methods: {
    getEl() {
      return this.$refs.layout
    }
  }
}
</script>

<style lang="scss" scoped>

.header-right-item + .header-right-item{
  margin-left: 40px;
}
// LOGO
::v-deep .ics-layout-header__left{
  img{
    width: auto;
  }
}
// MAIN
.app-layout-mian{
  box-sizing: border-box;
  height: 100%;
}
</style>

