import cloneDeep from 'lodash/cloneDeep'
export default {
  install(Vue, options) {
    /**
     * 请求参数处理为接口统一格式 http://192.168.0.200:6060/document/index?document_id=47
     * @param {*} params 搜索条件
     * @param {*} fuzzyWord 模糊查询属性集合
     * @returns
     */
    Vue.prototype.$formatParams = ({ pageNum = 0, pageSize = 0, total = 0, sort = '', ...params } = {}, fuzzyWord = []) => {
      const ret = {}
      if (params) {
        ret.data = cloneDeep(params)
      }

      ret.base = {
        page: `${pageNum}-${pageSize}`,
        sort
      }
      fuzzyWord.forEach(w => {
        if (params[w]) ret.data[w] = ('*' + params[w] + '*')
      })

      return ret
    }
  }
}
