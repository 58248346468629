import components from './components'
import installFn from './installFun'
import { registerSaaSModules } from './state'
import { hasDevConfig, setDevConfig } from './sdk/devStorage'

export let _Vue

const install = function(Vue, opts = {}) {
  _Vue = Vue
  _Vue.shared_opts = opts

  Vue.use(installFn)

  const { api, store, OpenDevStorage } = opts
  // Vue.prototype.$api = api
  Object.defineProperty(Vue.prototype, '$api', {
    get() { return api }
  })

  /** 是否开启配置开发调试 */
  if (OpenDevStorage) {
    if (!hasDevConfig()) {
      setDevConfig(false)
    }
  }

  components.forEach(component => {
    Vue.component(component.name, component)
  })

  store && registerSaaSModules(store, api)
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export {
  install
}
