<template>
  <div class="about">
    <div class="content-item">
      <img class="content-item--title" src="~shared/assets/about_images/title1.png" alt="">
      <div class="content-item--main">
        <p v-for="(t,index) in Options.companyProfile" :key="index" class="base">  {{ t }}  </p>
        <div style="margin-top: 60px">
          <p
            v-for="(t,index) in Options.companyProfile2"
            :key="index"
            class="bold"
          >
            {{ t }}
          </p>
        </div>
      </div>
    </div>
    <div class="cover-img">
      <img src="~shared/assets/about_images/cover1.jpg">
    </div>
    <div class="content-item">
      <div class="content-item--title right">
        <img src="~shared/assets/about_images/title2.png" alt="">
      </div>
      <div class="content-item--main">
        <p v-for="(t,index) in Options.messageFromTheChairman" :key="index" class="base">
          {{ t }}
        </p>
      </div>
    </div>
    <div class="cover-img">
      <img src="~shared/assets/about_images/cover2.jpg">
    </div>
    <div class="content-item">
      <img class="content-item--title" src="~shared/assets/about_images/title3.png" alt="">
      <img src="~shared/assets/about_images/illustrations3.png" style="height: 600px">
    </div>
    <div class="course">
      <div class="content-item">
        <div class="content-item--title right">
          <img src="~shared/assets/about_images/title4.png" alt="">
        </div>
        <div class="content-item--main">
          <p v-for="(t,index) in Options.developmentPath" :key="index" class="small white">
            <span>{{ t.split('——')[0] }}&nbsp; &nbsp;—— &nbsp; &nbsp;{{ t.split('——')[1] }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="content-item flex">
      <div class="flex-item">
        <img class="content-item--title" src="~shared/assets/about_images/title5.png" alt="">
        <div class="flex content-item--main">
          <ul class="flex-item">
            <li v-for="(t,index) in Options.qualificationHonor1" :key="index">{{ t }}</li>
          </ul>
          <ul class="flex-item">
            <li v-for="(t,index) in Options.qualificationHonor2" :key="index">{{ t }}</li>
          </ul>
        </div>
      </div>
      <div class="flex-item">
        <img src="~shared/assets/about_images/illustrations5.jpg" alt="">
      </div>
    </div>
    <div class="cover-img">
      <img src="~shared/assets/about_images/cover5.jpg">
    </div>
    <div class="content-item flex ">
      <div class="flex-item">
        <img src="~shared/assets/about_images/illustrations6.jpg" alt="">
      </div>
      <div class="flex-item">
        <div class="content-item--title right">
          <img src="~shared/assets/about_images/title6.png" alt="">
        </div>
        <div class="content-item--main">
          <span class="small">{{ Options.teamStrength }}</span>
        </div>
      </div>
    </div>
    <div class="cover-img">
      <img src="~shared/assets/about_images/cover6.jpg" alt="">
    </div>
  </div>
</template>
<script>
import Options from './options.js'
export default {
  name: 'About',
  data() {
    return {
      Options
    }
  }
}
</script>
<style scoped lang="scss">
$width:1200px;
.about {
  width: 100%;
  ul {
    list-style: none;
    li {
      font-size: 14px;
      font-weight: 400;
      color: #3c4353;
      line-height: 32px;
    }
  }
  .cover-img {
    min-width: $width;
    img {
      width: 100%;
      display: block;
    }
  }
  .content-item{
    width: $width;
    margin: 0 auto;
    padding: 50px 0;
    &.flex{
      display: flex;
      .flex-item + .flex-item{
        margin-left: 40px;
      }
    }
    &--title{
      margin-bottom: 50px;
      &.right{
        text-align: right;
      }
      img{
        display: inline-block;
      }
    }
    &--main{
      &.flex{
        display: flex;
      }
      .base{
        font-size: $--font-size-medium;
        color: $--color-text-primary;
        line-height: 1.875;
      }
      .bold{
        font-weight: bold;
        line-height: 3;
      }
      .small{
        font-size: $--font-size-base;
        line-height: 2.2;
      }
      .white{
        color: $--color-white;
      }
    }
  }
  .course {
    min-width: $width;
    background: url('../../assets/about_images/cover4.jpg');
  }
}
</style>
