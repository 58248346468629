import cloneDeep from 'lodash/cloneDeep'
import { getCurPlat, resolveSources } from '../sdk/index'

export default api => {
  const state = {
    menus: [],
    menusSource: [],
    userInfo: {}
  }

  const mutations = {
  // 用户
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
    },
    SET_MENUS: (state, data) => {
      state.menusSource = data
      state.menus = resolveSources(data)
    }
  }

  const actions = {
  // 获取用户信息
    getUserInfo({ state, commit }) {
      return new Promise((resolve, reject) => {
        api.getUserInfo().then(res => {
          const { data } = cloneDeep(res)
          commit('SET_USER_INFO', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 获取基础应用资源
    getMenus({ commit }) {
      return new Promise((resolve, reject) => {
        api.getSource({ type: 'menu', appType: getCurPlat() }).then(res => {
          const { data } = cloneDeep(res) || []
          // 开发环境 缓存配置
          commit('SET_MENUS', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出
    logout() {
      return new Promise((resolve, reject) => {
        api.loginOut().then(res => {
          location.reload()
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }

  return {
    namespaced: true,
    state,
    mutations,
    actions
  }
}

