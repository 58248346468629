import store from '@/store'

function findTreeNode(tree = [], code, keyName = 'code') {
  for (let index = 0; index < tree.length; index++) {
    const t = tree[index]
    const _t = { ...t }
    if (t[keyName] === code) {
      return _t
    } else {
      if (t.children) {
        const node = findTreeNode(t.children, code)
        if (node) return node
      }
    }
  }
}

export function has(routeCode, btnCode) {
  const menus = store.state.user.menus
  const curMenus = findTreeNode(menus, routeCode)
  if (!curMenus?.buttonPermission?.length) return false
  return curMenus.buttonPermission.includes(btnCode)
}

export function directive_has(el, binding, Vm) {
  if (has(Vm.context.$route.code, binding.value)) {
    el?.parentNode?.removeChild(el)
  }
}
