<template>
  <ics-dialog
    title="延长企业服务"
    :visible="isShowAuthDialog"
    width="560px"
    :request="onSave"
    :actions="true"
    destroy-on-close
    append-to-body
    custom-class="saas-auth-dialog"
    @close="TRIGGER_AUTH_DIALOG(false)"
  >
    <div class="auth-dialog">
      <p class="auth-dialog--label">尊敬的客户，请输入授权码延长企业服务：</p>
      <el-form ref="form" :model="form" @submit.native.prevent>
        <el-form-item prop="authCode" :rules="{required:true,message:'请输入授权码',trigger:'blur'}">
          <el-input v-model.trim="form.authCode" placeholder="请输入授权码" />
        </el-form-item>
      </el-form>
    </div>
  </ics-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'AuthDialog',
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapState('app', ['isShowAuthDialog'])
  },
  methods: {
    ...mapMutations('app', ['TRIGGER_AUTH_DIALOG']),
    onSave() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$api.updateCode(this.form).then(res => {
              this.TRIGGER_AUTH_DIALOG()
              location.reload()
              resolve()
            }).catch(err => reject(err))
          }
        }).catch(error => {
          console.log('[ error ] >', error)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.auth-dialog{
  width: 350px;
  margin: 0 auto;
  &--label{
    margin-bottom: 15px;
  }
}

</style>
<style lang="scss">
// 全局样式被重写优化
.saas-auth-dialog{
  .el-dialog__body{
    padding: 20px 20px 10px !important
  }
}
</style>
